import { RadioButtonGroup } from "./RadioGroup"

export const RadioGroupInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  ...props
}) => (
  <RadioButtonGroup
    label={props.label}
    onChange={handleChange(field.name)}
    value={field.value}
    errors={
      !!touched[field.name] && !!errors[field.name]
        ? [errors[field.name]]
        : undefined
    }
    disabled={props.disabled}
    sx={props.sx}
    options={options}
    {...props}
  />
)
