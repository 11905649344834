import * as schema from "@erinfo/data-schema"
import * as env from "@erinfo/env"
import { request } from "@erinfo/provider/src/data/request"
import { uploadFile } from "@erinfo/react-utils/src/helpers/upload-file"
import { duplicateImageErrorText } from "@erinfo/react-utils/src/helpers/validate-face-img/errors"
import { StatusCodes } from "http-status-codes"

interface IUserFaceParams {
  userId: string
  dataUrl?: string
  created?: string
}

export const createUserFace = async (params: IUserFaceParams) => {
  let { userId, dataUrl, created } = params
  if (!dataUrl && !created)
    throw new Error(`Must provide at least a data URL or created time`)

  try {
    if (dataUrl && !created) {
      const { data } = await request({
        path: `/img`,
        query: { type: `face` },
        signRequest: true,
      })
      await uploadFile({ dataURL: dataUrl, req: data.req })
      created = data.created
    }

    const { data } = await request({
      method: `POST`,
      path: `/img/${created}/face/add`,
      body: { uid: userId },
      signRequest: true,
    })
    data.created = created

    return data
  } catch (error) {
    let message = ``
    let messageData
    try {
      message = await error.text()
    } catch {
      console.log(`no error message`)
    }

    if (error) {
      const { status } = error
      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Status 409`)
      } else if (status === StatusCodes.NOT_FOUND) {
        throw new Error(`Face image does not match this person`)
      } else if (status === StatusCodes.EXPECTATION_FAILED) {
        throw new Error(`Face image does not match this person`)
      } else if (message) {
        try {
          messageData = JSON.parse(message)
        } catch {
          throw new Error(message)
        }
        if (messageData?.noFace || messageData?.faceConfidenceLow) {
          throw new Error(`No face was detected.`)
        }
        if (messageData?.hasMultipleFaces) {
          throw new Error(`There were multiple faces in the image.`)
        }
        if (messageData?.hasBeenAdded) {
          throw { message: duplicateImageErrorText }
        }

        throw new Error(message)
      } else {
        throw new Error(
          `There was an issue with this image.  Please retry or use a different photo.`,
        )
      }
    }
  }
}

export const deleteFacePicture = async (userId: string, faceId: string) => {
  try {
    const body: DataSchema.img.face.Delete = {
      [schema.nameIdU]: userId,
      [schema.nameFaceId]: faceId,
    }
    return request({
      method: `DELETE`,
      path: `/img/face`,
      body,
      signRequest: true,
    })
  } catch (error) {
    if (error.response) {
      const { status } = error
      let message = ``
      try {
        message = await error.text()
      } catch {
        console.log(`no error message`)
      }
      if (status === 409) {
        throw new Error(`Email already exists.`)
      } else if (message) {
        throw new Error(message)
      } else {
        throw new Error(error)
      }
    }
  }
}

export const matchFacePhoto = async (
  dataURL: string,
  providerEmail?: string,
) => {
  const {
    data: { created, req },
  } = await request({
    method: `GET`,
    path: `/img`,
    query: { type: `match` },
    signRequest: true,
  })
  try {
    await uploadFile({ dataURL, req })

    const { data: match } = await request({
      method: `GET`,
      path: `/img/${created}/face/match`,
      signRequest: true,
    })
    return { match, created }
  } catch (error) {
    void env.newrelic.browser.helpers.withNewRelic((nr) => {
      nr.noticeError(new Error(`Failed image match`), {
        created,
        userEmail: providerEmail,
      })
    })

    throw Object.assign(new Error(`Failed image match`), {
      status: error.status,
      details: { created },
    })
  }
}
