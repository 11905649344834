export const resizeImage = async (
  base64Str: string,
  maxWidth: number,
  maxHeight: number,
  format = `image/png`,
): Promise<string> => {
  if (!maxWidth || !maxHeight || !base64Str)
    throw new Error(`Cannot resize, missing parameter`)
  return new Promise((resolve) => {
    const img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement(`canvas`)
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext(`2d`)
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL(format))
    }
  })
}
