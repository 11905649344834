import { AppDistributionFirstnet } from "@erinfo/brand-ui/src/storybook/other/app-distribution/firstnet/_"
import { Attribution } from "@erinfo/brand-ui/src/storybook/typography/attribution/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { envFirstNetAuthUrl } from "@erinfo/env"
import { version } from "@erinfo/provider/package.json"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { encodeState } from "@erinfo/react-utils/src/helpers/string-encode"

const Landing = () => {
  const { checkToken } = useRematchDispatch((dispatch: Dispatch) => ({
    setDialogMessage: dispatch.notifications.setDialogMessage,
    storeProviderById: dispatch.user.storeProviderById,
    checkToken: dispatch.user.checkToken,
    signOut: dispatch.user.signOutFirstNet,
  }))
  const user = useSelector((state: RootState) => state.user)

  useEffect(() => {
    checkToken()

    const preventZoom = (e) => {
      if (e?.touches?.length === 2) {
        e.preventDefault()
      }
    }
    if (/iPad|iPhone|iPod|Android/.test(navigator.userAgent)) {
      window.document.addEventListener(`touchmove`, preventZoom, {
        passive: false,
      })
    }
    return () => {
      console.log(`removing event listener`)
      window.removeEventListener(`touchmove`, preventZoom)
    }
  }, [])

  const onClickSignIn = async () => {
    let url = envFirstNetAuthUrl
    if (window.ReactNativeWebView) {
      url = envFirstNetAuthUrl.slice(0, envFirstNetAuthUrl.indexOf(`state=`))
      url = `${url}state=${encodeState({ openNativeApp: true })}`
    }
    if (process.env.LOCAL_DEV) {
      url = envFirstNetAuthUrl.slice(0, envFirstNetAuthUrl.indexOf(`state=`))
      url = `${url}state=${encodeState({
        redirectUri: `https://${window.location.host}/oauth`,
      })}`
    }
    console.log(`opening URL: `, url)
    window.open(url, "_self") // we must use "window.open", instead of "window.location.href" to open in-app browser in a native app
  }

  return user?.token ? (
    <Navigate to="/app/identify" />
  ) : (
    <Box
      display="flex"
      sx={{ flex: 1, flexDirection: `column`, justifyContent: `space-between` }}
    >
      <BorderBox sx={{ justifyContent: `space-between`, marginTop: 5 }}>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
          }}
        >
          <StyledButton
            variant="contained"
            sx={{ mt: 1, width: 318, mb: 2 }}
            onClick={onClickSignIn}
          >
            Sign in with FirstNet
          </StyledButton>
        </Box>
      </BorderBox>
      <StyledAttribution>
        <AppDistributionFirstnet className="sign-in__market-logo" />
        <Attribution className="sign-in__attribution" version={`v${version}`} />
      </StyledAttribution>
    </Box>
  )
}

const StyledAttribution = styled(`div`)({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,

  "& .sign-in__attribution": {
    marginTop: 5,
  },
})

export default Landing
