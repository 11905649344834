import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { Dispatch } from "@erinfo/provider/src/store"
import { eraseCookie, getCookie } from "@erinfo/react-utils/src/helpers/cookies"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const Impersonate = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(``)
  const { impersonate } = useRematchDispatch((dispatch: Dispatch) => ({
    impersonate: dispatch.user.impersonate,
  }))

  useEffect(() => {
    const impersonationRequest = getCookie(`impersonateData`)
    if (impersonationRequest) {
      const request = JSON.parse(impersonationRequest)
      const { email, password, userId } = request
      if (!email || !password || !userId) {
        eraseCookie(`impersonateData`)
        return setError(`Invalid Impersonation Request`)
      }
      void impersonate({ email, password, userId }).then(() =>
        navigate(`/identify`),
      )
      eraseCookie(`impersonateData`)
    } else {
      setError(`No impersonation cookie present`)
    }
  }, [])

  return (
    <BorderBox maxWidth={350}>
      {error ? (
        <Typography variant="h5" sx={{ color: `error.main` }}>
          {error}
        </Typography>
      ) : (
        <Typography variant="h5">Impersonating User...</Typography>
      )}
    </BorderBox>
  )
}

export default Impersonate
