import React from "react"

export default () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30.5" cy="30" r="30" fill="#E31B0C" />
    <path
      d="M22.8788 28.2862C24.9188 32.2953 28.2055 35.5678 32.2147 37.622L35.3313 34.5053C35.7138 34.1228 36.2805 33.9953 36.7763 34.1653C38.363 34.6895 40.0772 34.9728 41.8338 34.9728C42.613 34.9728 43.2505 35.6103 43.2505 36.3895V41.3337C43.2505 42.1128 42.613 42.7503 41.8338 42.7503C28.5313 42.7503 17.7505 31.9695 17.7505 18.667C17.7505 17.8878 18.388 17.2503 19.1672 17.2503H24.1255C24.9047 17.2503 25.5422 17.8878 25.5422 18.667C25.5422 20.4378 25.8255 22.1378 26.3497 23.7245C26.5055 24.2203 26.3922 24.7728 25.9955 25.1695L22.8788 28.2862Z"
      fill="white"
    />
  </svg>
)
