import { useEffect } from "react"
import { getFirstNetToken } from "@erinfo/provider/src/data/index"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

const OAuth = () => {
  const { storeProviderById } = useRematchDispatch((dispatch: Dispatch) => ({
    storeProviderById: dispatch.user.storeProviderById,
  }))
  const user = useSelector((state: RootState) => state.user)
  console.log("Window location", window.location)

  const retrieveToken = async () => {
    const hash = window.location.hash

    const items = hash.split("&")
    const code = items.find((item) => item.includes("code"))
    const state = items.find((item) => item.includes("state"))
    console.log("items", items, code, state)

    const [_, codeValue] = code?.split("=") ?? []
    const [__, stateValue] = state?.split("=") ?? []

    console.log("codeValue", codeValue)
    console.log("stateValue", stateValue)
    const response = await getFirstNetToken(codeValue, stateValue)
    console.log("code", code, "response", response)

    const { token, sub, redirectUri } = response || {}

    if (redirectUri) {
      window.location.href = redirectUri
    }
    if (token) {
      storeProviderById({ token, sub })
    }
  }

  useEffect(() => {
    retrieveToken()
  }, [])

  return user?.token ? (
    <Navigate to="/app/identify" />
  ) : (
    <Box sx={{ marginTop: 2 }}>
      <h1>Authenticating...</h1>
    </Box>
  )
}

export default OAuth
