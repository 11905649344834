import { AddMemberProvider } from "@erinfo/brand-ui/src/storybook/other/add-member-flow/add-member-context"
import AddMemberStep1 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-1"
import AddMemberStep2 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-2"
import AddMemberStep3 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-3"
import RegistrationLayout from "@erinfo/provider/src/layouts/Registration"
import { validationCallback } from "@erinfo/provider/src/lib/imageUtils"
import { Navigate } from "react-router-dom"

import Protected from "./components/Protected"
import AppLayout from "./layouts/App"
import AuthLayout from "./layouts/Auth"
import Impersonate from "./pages/auth/Impersonate"
import Landing from "./pages/auth/Landing"
import SignIn from "./pages/auth/SignIn"
import OAuth from "./pages/auth/OAuth"
import Identify from "./pages/identify"
import PatientDisposition from "./pages/patientDisposition"
import MemberDetail from "./pages/memberDetail"

const routes = ({ user, setDialogMessage, createPatientMember }) => [
  {
    path: `/`,
    element: <AuthLayout variant="login" />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: `/sign-in`,
        element: <SignIn />,
      },
      {
        path: `/oauth`,
        element: <OAuth />,
      },
      {
        path: `/impersonate`,
        element: <Impersonate />,
      },
    ],
  },
  {
    path: `/identify`,
    element: (
      <Protected>
        <AppLayout>
          <Identify />
        </AppLayout>
      </Protected>
    ),
  },
  {
    path: `/disposition`,
    element: (
      <Protected>
        <AppLayout sx={{ backgroundColor: `#E2E2E2` }}>
          <PatientDisposition validationCallback={validationCallback} />
        </AppLayout>
      </Protected>
    ),
  },
  {
    path: `/member-detail/:memberId`,
    element: (
      <Protected>
        <AppLayout>
          <MemberDetail />
        </AppLayout>
      </Protected>
    ),
  },
  {
    path: `/add-member`,
    element: (
      <Protected>
        <AddMemberProvider
          user={user}
          setDialogMessage={setDialogMessage}
          createNewMember={createPatientMember}
          returnUrl="/app/identify"
          proApp={true}
        >
          <RegistrationLayout />
        </AddMemberProvider>
      </Protected>
    ),
    children: [
      {
        path: `step-1`,
        element: <AddMemberStep1 validationCallback={validationCallback} />,
      },
      {
        path: `step-2`,
        element: <AddMemberStep2 />,
      },
      {
        path: `step-3`,
        element: <AddMemberStep3 />,
      },
    ],
  },
  {
    path: `*`,
    element: <Navigate to="/identify" />,
  },
]

export default routes
