import { Models } from "@rematch/core"

import { matches } from "./matches"
import { memberDetail } from "./memberDetail"
import { notifications } from "./notifications"
import { user } from "./user"

export interface RootModel extends Models<RootModel> {
  user: typeof user
  notifications: typeof notifications
  matches: typeof matches
  memberDetail: typeof memberDetail
}

export const models: RootModel = { user, notifications, matches, memberDetail }
