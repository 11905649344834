import { ReactComponent as IMarkHorizontal } from "@erinfo/brand-ui/src/assets/svg/logo-pro.svg"
import { version } from "@erinfo/provider/package.json"
import { useMainMenuActions } from "@erinfo/provider/src/hooks/use-main-menu-actions"
import { Close } from "@mui/icons-material"
import AccessTime from "@mui/icons-material/AccessTime"
import CameraEnhanceIcon from "@mui/icons-material/CameraEnhance"
import Edit from "@mui/icons-material/Edit"
import Logout from "@mui/icons-material/Logout"
import Person from "@mui/icons-material/Person"
import PersonAdd from "@mui/icons-material/PersonAdd"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { styled } from "@mui/material/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import { FC } from "react"

const Logo = styled(IMarkHorizontal)({
  width: `121.22px`,
  height: `40px`,
})

const StyledListItemIcon = styled(ListItemIcon)({
  "&&": { minWidth: 45 },
})

export const MobileMainMenu: FC = () => {
  const {
    navigate,
    closeMenu,
    openRecentMember,
    openEditCurrentMember,
    openProfile,
    openAddMember,
    sendEmail,
    open,
    user,
    logout,
    disableMemberDetail,
  } = useMainMenuActions()

  return (
    <SwipeableDrawer
      variant="persistent"
      anchor="bottom"
      open={open}
      onClose={closeMenu}
      disableSwipeToOpen={false}
      sx={{
        width: `100%`,
        "& .MuiDrawer-paper": {
          width: `100%`,
          height: `calc(100vh - env(safe-area-inset-top))`,
        },
      }}
    >
      <Box display="flex" sx={{ fontSize: `0.75rem`, flexDirection: `column` }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: `1px solid #E0E0E0`, padding: 1 }}
        >
          <Logo />
          <IconButton onClick={closeMenu}>
            <Close />
          </IconButton>
        </Box>
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(`/app/identify`)
              closeMenu()
            }}
          >
            <StyledListItemIcon>
              <CameraEnhanceIcon fontSize="small" color="error" />
            </StyledListItemIcon>
            <ListItemText sx={{ color: `error.main` }}>
              Identify New
            </ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={openRecentMember}>
            <StyledListItemIcon>
              <AccessTime fontSize="small" />
            </StyledListItemIcon>
            <ListItemText>Recent Member Activity</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={openEditCurrentMember}
            disabled={disableMemberDetail}
          >
            <StyledListItemIcon>
              <Edit fontSize="small" />
            </StyledListItemIcon>
            <ListItemText>Edit Current Member</ListItemText>
          </MenuItem>
          <MenuItem onClick={openAddMember}>
            <StyledListItemIcon>
              <PersonAdd fontSize="small" />
            </StyledListItemIcon>
            <ListItemText>Add Member</ListItemText>
          </MenuItem>
          <MenuItem onClick={openProfile}>
            <StyledListItemIcon>
              <Person fontSize="small" />
            </StyledListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>

          <Divider />

          <Box p={2}>
            <Typography mt={2} sx={{ opacity: 0.6, fontSize: 14 }}>
              Help
            </Typography>
            <MenuItem
              component="a"
              href="https://erinfo.me/faqs/"
              target="_blank"
            >
              <ListItemText>Using ERinfo</ListItemText>
            </MenuItem>
            <MenuItem onClick={sendEmail(`profeedback@erinfo.me`)}>
              <ListItemText>Send Feedback</ListItemText>
            </MenuItem>
          </Box>

          <Divider />

          <MenuItem onClick={logout}>
            <StyledListItemIcon>
              <Logout fontSize="small" />
            </StyledListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </MenuList>
      </Box>
      <Box sx={{ position: `absolute`, bottom: 15, right: 30 }}>
        <Typography sx={{ color: `#888`, fontSize: `0.8rem` }}>
          v {version}
        </Typography>
      </Box>
    </SwipeableDrawer>
  )
}
