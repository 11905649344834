/**
 * From the document "CSO.IAM_AID.FirstNet_may_23_not final.pdf"
 * @see "6.3.4 URL Endpoints"
 * @see "8.1.3 URL Endpoints"
 */
const logoutSubdomain = process.env.AWS_STAGE !== `production` ? `stage.` : ``

const stage = process.env.AWS_STAGE !== `production` ? `stage` : `idp`
export const clientId =
  process.env.AWS_STAGE !== `production` ? `m36003` : `m36004`

let returnUrlSubdomain:
  | "dev-pro"
  | "staging-pro"
  | "next-pro"
  | "app-pro" = `app-pro` // "app" in "production"

switch (process.env.AWS_STAGE) {
  case `develop`:
    returnUrlSubdomain = `dev-pro`
    break
  case `staging`:
    returnUrlSubdomain = `staging-pro`
    break
  case `stagingnext`:
    returnUrlSubdomain = `next-pro`
    break
}

export const redirectUri = `https://${returnUrlSubdomain}.erinfo.me/oauth`

/**
 * @todo use "qs" to stringify from object params definition
 */
const authParams =
  `?response_type=code` +
  `&client_id=${clientId}` +
  `&redirect_uri=https%3A%2F%2F${returnUrlSubdomain}.erinfo.me%2Foauth` +
  `&scope=openid+profile+email` +
  `&response_mode=fragment` +
  `&nonce=` +
  `&state=fEA2yTYmcbdWlWTnEact`

export const envFirstNetAuthUrl = `https://oidc.${stage}.flogin.att.com/mga2/sps/oauth/oauth20/authorize${authParams}`
export const envFirstNetLogoutUrl = `https://fcontent.${logoutSubdomain}att.com/dynamic/iamLRR/LrrController?IAM_OP=logout&returnURL=${encodeURIComponent(
  `https://${returnUrlSubdomain}.erinfo.me`,
)}`

/** Used on the backend to get the token from the FirstNet authorization code */
export const firstNetTokenUrl = `https://oidc.${stage}.flogin.att.com/mga2/sps/oauth/oauth20/token`
