import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { Navigate } from "react-router-dom"

// import useAuth from "../hooks/use-auth"
import Loader from "./Loader"

const Protected = ({ children }) => {
  // const { isLoading } = useAuth()
  // useRedirects({ protectedRoute: true })
  const isLoading = false
  const token = localStorage.getItem(`token`)
  if (!token) {
    return <Navigate to="/" />
  }

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ height: `100vh` }}>
          <Loader>
            <Typography variant="h6">Loading...</Typography>
          </Loader>
        </Box>
      </Container>
    )
  }

  return <>{children}</>
}

export default Protected
