import { uploadFile } from "@erinfo/react-utils/src/helpers/upload-file"
import { StatusCodes } from "http-status-codes"

import { request, responseType } from "./request"

export const getProviderById = async (
  id: DataSchema.id,
): Promise<DataSchema.provider.post> => {
  const {
    data: { user },
  } = await request({
    path: `/users/${id}`,
    signRequest: true,
  })
  console.log(`getProviderById -> user`, user)

  return user
}

export const getProviderByEmail = async (email: string) => {
  const {
    data: { user },
  } = await request({ path: `/users/email/${email}`, signRequest: true })
  return user
}

/**
 * Add new patient
 * @return  {string} id new patient
 */
export const createUser = async (
  userData: DataSchema.user.post,
  isProvider?: boolean,
) => {
  try {
    const { data } = await request({
      method: `POST`,
      path: `/users`,
      body: userData,
      type: isProvider ? responseType.text : responseType.json,
    })

    return isProvider ? data : data?.id
  } catch (err) {
    const { status, statusText } = err as Response
    if (status === 409) throw new Error(`Email already exists.`)
    if (status === 404) throw new Error(`Unavailable endpoint`)
    if (status === 501) throw new Error(`Server error`)
    if (statusText) throw new Error(statusText)
    throw new Error(err)
  }
}

/**
 * Add new patient with disposition
 * @return  {string} id new patient
 */
export const createNewUserWithDisposition = async (
  created: number,
  disposition: any,
) => {
  try {
    const { data } = await request({
      method: `POST`,
      path: `/img/${created}/face/disposition`,
      body: disposition,
      type: responseType.json,
      signRequest: true,
    })
    return data?.userID
  } catch (err) {
    const { status, statusText } = err as Response
    if (status === 409) throw new Error(`Email already exists.`)
    if (status === 404) throw new Error(`Unavailable endpoint`)
    if (status === 501) throw new Error(`Server error`)
    if (statusText) throw new Error(statusText)
    throw new Error(err)
  }
}

export const getUserById = async (id: string) => {
  const {
    data: { user },
  } = await request({
    method: `GET`,
    path: `/users/${id}`,
    signRequest: true,
  })
  return user
}

export const getFirstNetToken = async (code: string, state?: string) => {
  const { data } = await request({
    method: `POST`,
    path: `/oauth`,
    body: { code, state },
    type: responseType.json,
  })
  return data
}

export const logoutFirstNet = async () => {
  try {
    await request({ method: `GET`, path: envFirstNetLogoutUrl })
    console.log(`Firstnet logout successful`)
  } catch (err) {
    console.log(err)
  }
}

export const archiveMember = async (memberId: DataSchema.id) => {
  try {
    await request({
      method: `POST`,
      path: `/users/archive/${memberId}`,
      signRequest: true,
    })
  } catch (err) {
    const error = err as Response
    if (error.statusText) {
      throw new Error(error.statusText)
    }
    throw new Error(err)
  }
}

export const deleteUser = async () => {
  try {
    await request({
      method: `DELETE`,
      path: `/users`,
      signRequest: true,
    })
  } catch (err) {
    const error = err as Response
    if (error.statusText) {
      throw new Error(error.statusText)
    }
    throw new Error(err)
  }
}

export const updateUser = async (
  id: DataSchema.id,
  userData: DataSchema.user.id.put,
  isProvider?: boolean,
) => {
  try {
    const body = isProvider ? { isProvider: true, ...userData } : userData
    const { data } = await request({
      method: `PUT`,
      path: `/users/${id}`,
      body,
      signRequest: true,
    })

    return data
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Email already exists.`)
      } else if (data && data.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    }
  }
}

/**
 * Add new other pic
 * @return  {string} id new pic
 */
export const addOtherPicture = async (id: DataSchema.id, img: string) => {
  try {
    const {
      data: { req, created },
    } = await request({
      method: `GET`,
      path: `/img`,
      query: { type: `other` },
      signRequest: true,
    })
    await uploadFile({ dataURL: img, req })

    await request({
      method: `PUT`,
      path: `/img/${created}/other`,
      body: { uid: id },
      signRequest: true,
    })
    return created?.toString()
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Email already exists.`)
      } else if (data && data.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    }
  }
}
