import { Footer } from "@erinfo/brand-ui/src/storybook/other/footer"
import { Logo } from "@erinfo/brand-ui/src/storybook/other/logo/_"
import Notifications from "@erinfo/consumer/src/components/Notifications"
import { SignupBrand } from "@erinfo/consumer/src/components/SignupBrand"
import { CssBaseline, Hidden } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FC } from "react"
import { Outlet } from "react-router-dom"

const StyledLogo = styled(Logo)({
  margin: `0 auto 38px`,
})

const Root = styled(`div`)({
  margin: `0 auto`,
  justifyContent: `center`,
  alignItems: `center`,
  display: `flex`,
  minWidth: `100vw`,
  flexDirection: `column`,
  position: `relative`,
  "@media (min-width: 480px)": {
    minHeight: `100vh`,
    footer: {
      position: `absolute`,
      bottom: `0`,
      padding: `8px 0`,
    },
  },
  "@media (max-width: 480px)": {
    justifyContent: `flex-start`,
    overflowY: `auto`,
    height: `100%`,
  },
})

const Wrapper = styled(`div`)((props) => ({
  background: `white`,
  paddingTop: props.theme.spacing(3),
  paddingBottom: props.theme.spacing(3),
  display: `flex`,
  "@media (min-width: 480px)": {
    alignItems: `center`,
    justifyContent: `center`,
    marginTop: `15vh`,
    marginBottom: `5vh`,
  },
  flex: 1,
}))

const WrapperSignup = styled(Wrapper)({
  flexWrap: `wrap`,
  flexDirection: `row`,
})

const WrapperSignin = styled(Wrapper)({
  flexDirection: `column`,
  alignItems: `center`,
})

const StyledSignupBrand = styled(SignupBrand)({
  "@media (min-width: 470px)": { marginRight: 20 },
})

type componentVariant = "login" | "signup" | "plan"

const Auth: FC<{
  variant: componentVariant
  headerText?: string
}> = ({ children, variant }) => {
  return (
    <Root sx={{ bgcolor: variant === `plan` ? `background.default` : `white` }}>
      <CssBaseline />
      {variant === `login` && (
        <WrapperSignin>
          <StyledLogo version="pro_vertical" />
          {children}
          <Outlet />
          <Hidden mdDown>
            <Footer />
          </Hidden>
        </WrapperSignin>
      )}
      {variant === `signup` && (
        <WrapperSignup>
          <StyledSignupBrand />
          {children}
          <Outlet />
        </WrapperSignup>
      )}
      <Notifications />
    </Root>
  )
}

export default Auth
