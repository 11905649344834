import { OnlineStatus } from "@erinfo/brand-ui/src/storybook/other/online-status"
import CallIcon from "@erinfo/provider/src/assets/images/callIcon"
import MessageIcon from "@erinfo/provider/src/assets/images/messageIcon"
import { AvatarList } from "@erinfo/provider/src/components/AvatarList"
import * as Dash from "@erinfo/provider/src/components/dashboard/index"
import { CallEmergencyContacts } from "@erinfo/provider/src/components/dialogs/CallEmergencyContacts"
import { DocumentPictures } from "@erinfo/provider/src/components/dialogs/DocumentPictures"
import { MemberPictures } from "@erinfo/provider/src/components/dialogs/MemberPictures"
import { MsgEmergencyContacts } from "@erinfo/provider/src/components/dialogs/MsgEmergencyContacts"
import Loader from "@erinfo/provider/src/components/Loader"
import { MemberBottomMenu } from "@erinfo/provider/src/components/MemberBottomMenu"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { getPresignedImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Button, Hidden, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import SwipeableViews from "react-swipeable-views"

const MemberDetail = () => {
  const { memberId } = useParams()
  const [slideIdx, setSlideIdx] = useState(1)
  const [callContactOpen, setCallContactOpen] = useState(false)
  const [msgContactOpen, setMsgContactOpen] = useState(false)
  const [memberPictureOpen, setMemberPictureOpen] = useState<
    string | undefined
  >()
  const [memberDocumentOpen, setMemberDocumentOpen] = useState<
    string | undefined
  >()
  const user = useSelector((state: RootState) => state.memberDetail)
  const phone = useSelector((state: RootState) => state.user.phone)
  const { toggleMenu, openMemberDetail } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      toggleMenu: dispatch.notifications.toggleMenu,
      openMemberDetail: dispatch.memberDetail.openMemberDetail,
    }),
  )

  useEffect(() => {
    setSlideIdx(1)
    if (
      memberId &&
      !user.fetching &&
      !user.similarity &&
      user.id !== memberId
    ) {
      void openMemberDetail({ userId: memberId })
    }
  }, [user, memberId])

  return !user?.profile ? (
    <Loader />
  ) : (
    <Box
      sx={{
        "@media (min-width: 480px)": {
          marginBottom: 4,
        },
        "@media (max-width: 480px)": {
          width: `100vw`,
        },
        paddingTop: 1,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <OnlineStatus />
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ width: `100%`, maxWidth: 390, pl: 1, pr: 1 }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <AvatarList
            user={user}
            onClick={(type: string) => setMemberPictureOpen(type)}
          />
          <MemberPictures
            open={memberPictureOpen}
            close={() => setMemberPictureOpen(undefined)}
            member={user}
          />
          <DocumentPictures
            open={!!memberDocumentOpen}
            close={() => setMemberDocumentOpen(undefined)}
            document={memberDocumentOpen}
            getImage={(created) =>
              getPresignedImageUrl(created, user.otherPictures)
            }
          />
          {user.similarity && user.identifiedImage && (
            <Typography
              sx={{
                fontWeight: `500`,
                fontSize: 12,
                color: `success.main`,
                marginTop: 1,
              }}
            >
              {user.similarity?.toFixed(1)}% MATCH
            </Typography>
          )}
          {user.similarity && !user.identifiedImage && (
            <Typography
              sx={{
                fontWeight: `500`,
                fontSize: 12,
                color: `error.main`,
                marginTop: 1,
              }}
            >
              {/* {user.similarity?.toFixed(1)}% MATCH */}
              MATCHED MEMBER
            </Typography>
          )}
          {!user.similarity && (
            <Typography
              sx={{
                fontWeight: `500`,
                fontSize: 12,
                color: `success.main`,
                marginTop: 1,
              }}
            >
              ADDED MEMBER
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box display="flex">
            <Button onClick={() => setMsgContactOpen(true)}>
              <MessageIcon />
            </Button>
            <Button onClick={() => setCallContactOpen(true)}>
              <CallIcon />
            </Button>
          </Box>
          <Typography sx={{ fontSize: 10, opacity: 0.6 }}>
            ALERT EMERGENCY CONTACTS
          </Typography>
          <CallEmergencyContacts
            member={user}
            open={callContactOpen}
            close={() => setCallContactOpen(false)}
          />
          <MsgEmergencyContacts
            member={user}
            phone={phone}
            open={msgContactOpen}
            close={() => setMsgContactOpen(false)}
          />
        </Box>
      </Box>
      <Hidden smDown>
        <Box
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            marginTop: 2,
            marginBottom: 2,
            justifyContent: `space-between`,
            alignItems: `flex-start`,
            "@media (min-width: 480px)": {
              width: `calc(100vw - 50px)`,
            },
            maxWidth: 1280,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              "@media (max-width: 480px)": { width: `100%` },
            }}
          >
            <Dash.MedicalPanel user={user} />
          </Box>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              "@media (max-width: 480px)": { width: `100%` },
            }}
          >
            <Dash.PersonalPanel user={user} context="provider" />
          </Box>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              "@media (max-width: 480px)": { width: `100%` },
            }}
          >
            <Dash.IdentifyingPanel user={user} sx={{ mb: 3 }} />
            <Dash.DocumentsPanel
              user={user}
              openDocument={setMemberDocumentOpen}
            />
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <SwipeableViews index={slideIdx} containerStyle={{ width: `100vw` }}>
          <Dash.MedicalPanel user={user} />
          <Dash.PersonalPanel user={user} context="provider" />
          <Box SX={{ display: `flex`, flexDirection: `column` }}>
            <Dash.IdentifyingPanel user={user} />
            <Dash.DocumentsPanel
              user={user}
              openDocument={setMemberDocumentOpen}
            />
          </Box>
        </SwipeableViews>
        <MemberBottomMenu onSwitch={setSlideIdx} toggleMenu={toggleMenu} />
      </Hidden>
    </Box>
  )
}

export default MemberDetail
