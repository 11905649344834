/** @jsxImportSource @emotion/react */
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { FC, FormEvent } from "react"
import { Box, Typography } from "@mui/material"
import { Error } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import MenuButton from "@erinfo/brand-ui/src/storybook/buttons/menu-button"

const StyledBox = styled(Box)({
  background: `white`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  border: `1px solid rgba(0, 0, 0, 0.12)`,
  "@media (max-width: 480px)": {
    height: `100%`,
  },
})

export const DispositionWrapper: FC<{
  onSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  onBack: () => void
  user: any
  saving?: boolean
  setFieldValue: any
}> = ({ children, onBack, user, saving, onSubmit, setFieldValue }) => {
  const navigate = useNavigate()
  const isMatch = !!user?.pictures?.[0]

  return (
    <StyledBox
      sx={{
        marginBottom: "55px",
        "@media(min-width: 480px)": {
          width: `calc(100vw - 50px)`,
          maxWidth: 960,
          minHeight: 650,
        },
        "@media(max-width: 480px)": {
          width: `100%`,
          minHeight: `calc(100vh - 138px)`,
        },
      }}
    >
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          paddingY: 1,
          paddingX: 3,
          borderBottom: `1px solid #E0E0E0`,
          "@media(max-width: 480px)": {
            position: `sticky`,
            top: 0,
            background: `white`,
            zIndex: 5,
          },
        }}
      >
        <Error color="error" sx={{ marginRight: 2 }} />
        <Typography variant="h6">Patient Disposition</Typography>
      </Box>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `flex-start`,
          alignItems: `center`,
          position: `relative`,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
          {user?.identifiedImage && (
            <img
              src={user?.identifiedImage}
              css={{
                marginTop: 2,
                maxWidth: 150,
                maxHeight: 150,
                objectFit: "cover",
                borderRadius: 5,
              }}
            />
          )}
          {user?.pictures?.length && (
            <img
              src={user?.pictures?.[0]?.src}
              css={{
                marginTop: 2,
                marginLeft: 5,
                maxWidth: 150,
                maxHeight: 150,
                objectFit: "cover",
                borderRadius: 5,
              }}
            />
          )}
        </Box>
        {user?.similarity === 0 ? (
          <Typography color="secondaryGrey">New Patient</Typography>
        ) : (
          <>
            <Typography sx={{ color: `success.main` }}>
              {user?.similarity?.toFixed(0)}% Match
            </Typography>
            {user?.profile?.firstName || user?.profile?.lastName ? (
              <Typography
                sx={{
                  color: `primary.main`,
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {user?.profile?.firstName || "Unknown"}{" "}
                {user?.profile?.lastName || "Unknown"}
              </Typography>
            ) : (
              <Typography sx={{ color: `primary.main` }}>
                Unknown Name
              </Typography>
            )}
          </>
        )}
        {children}
      </Box>
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          height: 58,
          backgroundColor: `#F5F5F5`,
          justifyContent: `space-between`,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {isMatch ? (
          <MenuButton
            label="Skip"
            options={[
              {
                label: `View Profile`,
                onClick: () => {
                  navigate(`/member-detail/${user.id}`)
                },
              },
              {
                label: "ID Next",
                onClick: onBack,
              },
            ]}
            color="error"
            disabled={saving}
            loading={saving}
          />
        ) : (
          <StyledButton
            onClick={onBack || (() => {})}
            color="primary"
            disabled={saving}
          >
            New Pic
          </StyledButton>
        )}
        <Box>
          <MenuButton
            label="Save"
            options={[
              {
                label: `${isMatch ? "View" : "Add"} Profile`,
                onClick: () => {
                  setFieldValue("action", isMatch ? "view" : "add")
                  onSubmit()
                },
              },
              {
                label: "ID Next",
                onClick: () => {
                  setFieldValue("action", "next")
                  onSubmit()
                },
              },
            ]}
            color="success"
            disabled={saving}
            loading={saving}
          />
        </Box>
      </Box>
    </StyledBox>
  )
}
