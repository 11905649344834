import { envAPIEndpoint } from "@erinfo/env"
import * as env from "@erinfo/env"
import { getUserSession } from "@erinfo/react-utils/src/helpers/amplify"
import * as qs from "@erinfo/react-utils/src/helpers/qs"
import { StatusCodes } from "http-status-codes"

export enum responseType {
  json = `json`,
  text = `text`,
}

const getData = async (response: Response, type: req["type"]) => {
  const data = await response[responseType.text]()
  if (!data) return

  return type === responseType.json ? JSON.parse(data) : data
}

export interface req extends Pick<RequestInit, "method"> {
  path: string
  method?: string
  bodyText?: string
  body?: { [key: string]: any }
  query?: { [key: string]: any }
  headers?: RequestInit["headers"]
  type?: responseType
  baseUrl?: string
  signRequest?: boolean
}

export const request = async <Data = any>({
  path,
  method = `GET`,
  bodyText,
  body,
  query,
  headers = {},
  type = responseType.json,
  baseUrl = envAPIEndpoint,
  signRequest = false,
  ...config
}: req) => {
  let cognitoJwtToken, fnJwtToken
  if (signRequest) {
    // first try FN token
    fnJwtToken = localStorage.getItem(`token`)
    if (!fnJwtToken) {
      const cognitoIdToken = await getUserSession()
      cognitoJwtToken = cognitoIdToken.jwtToken
    }
  }

  // console.log(`>>>>>>>>> token found: `, fnJwtToken)

  let url = `${baseUrl}${path}`
  if (query && Object.keys(query).length > 0) {
    url += (url.includes(`?`) ? `&` : `?`) + qs.format(query)
  }

  const configuration: RequestInit = {
    method,
    headers: {
      ...headers,
      ...((fnJwtToken || cognitoJwtToken) && {
        Authorization: `bearer ${fnJwtToken || cognitoJwtToken}`,
        // provider: `p/fn`,
      }),
      ...(!!body && { "Content-Type": `application/json` }),
    },
    body: body ? JSON.stringify(body) : bodyText != null ? bodyText : undefined,
    ...config,
  }

  const response = await fetch(url, configuration)

  switch (true) {
    case response.status === StatusCodes.UNAUTHORIZED:
      throw response
    case response.status === StatusCodes.FORBIDDEN:
      throw response
    /**
     * @IMPORTANT has to check after specific error "statusCode"s
     */
    case !response.ok:
      if (
        response.status >= StatusCodes.INTERNAL_SERVER_ERROR &&
        response.status < 600
      ) {
        window.clarity(`upgrade`, `HTTP status ${response.status}`)
        await env.newrelic.browser.helpers.withNewRelic((nr) => {
          const error = new Error(
            `HTTP request to ${path} produced status ${response.status}`,
          )
          nr.noticeError(error, { url, body: JSON.stringify(body) })
        })
      }
      throw response

    default:
      const data: Data = await getData(response, type)
      return { data, response }
  }
}
