import { DialogStandard } from "@erinfo/brand-ui/src/storybook/notifications/dialogs/basic/v2"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { forwardRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Notifications = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { setDialogMessage, setSnackbarMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
    }),
  )
  const snackbar = useSelector(
    (state: RootState) => state.notifications.snackbar,
  )
  const dialog = useSelector((state: RootState) => state.notifications.dialog)

  useEffect(() => {
    if (snackbar.msg !== ``) {
      setSnackbarOpen(true)
    }

    return () => {
      setTimeout(() => {
        setSnackbarMessage(``)
        setSnackbarOpen(false)
      }, 5000)
    }
  }, [snackbar.msg])

  useEffect(() => {
    const { msg } = dialog
    if (msg) {
      setDialogOpen(true)
    } else {
      setDialogOpen(false)
    }
  }, [dialog.msg])

  const closeSnackbar = () => setSnackbarOpen(false)

  return (
    <>
      <DialogStandard
        disableClose={dialog.disableClose || false}
        open={dialogOpen}
        onClose={() => {
          setDialogMessage({})
          setDialogOpen(false)
        }}
        onClickYesEvent={() => {
          if (dialog.onYes) {
            setDialogMessage({})
            dialog.onYes()
          } else {
            setDialogOpen(false)
            setDialogMessage({})
          }
        }}
        onClickNoEvent={
          dialog.onNo
            ? () => {
                setDialogMessage({})
                if (dialog.onNo) dialog.onNo()
              }
            : undefined
        }
        alternativeYesLabel={dialog.altYesLabel}
        alternativeNoLabel={dialog.altNoLabel}
        title={dialog.title || ``}
        msg={dialog.msg}
      />

      <Snackbar
        anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
        style={{ width: `320px`, marginBottom: 20 }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity="success"
          icon={false}
          style={{ width: `100%` }}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Notifications
