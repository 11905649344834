import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { DocumentsDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/Documents"
import { FacePhotosDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/FacePhotos"
import { IdentifyingDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/Identifying"
import { MedicalInfoDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/MedicalInfo"
import { PersonalDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/Personal"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import * as api from "@erinfo/provider/src/data"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import {
  AccountCircle,
  CreditCard,
  Face,
  MedicalServices,
  Visibility,
} from "@mui/icons-material"
import { Box } from "@mui/material"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { useState } from "react"
import { useSelector } from "react-redux"
import { CameraType } from "expo-camera"

const MEDICAL = `medical`
const PERSONAL = `personal`
const IDENTIFYING = `identifying`
const DOCUMENTS = `documents`
const PHOTOS = `photos`

export const EditCurrentMember = () => {
  const [editModalOpen, setEditModalOpen] = useState<string | null>(null)
  const open = useSelector(
    (state: RootState) => state.notifications.editCurrentMemberOpen,
  )
  const member = useSelector((state: RootState) => state.memberDetail)
  const {
    toggleEditCurrentMember,
    updateMember,
    storeDocuments,
    storeMemberFacePicture,
    deleteMemberFacePicture,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleEditCurrentMember: dispatch.notifications.toggleEditCurrentMember,
    updateMember: dispatch.memberDetail.updateMember,
    storeDocuments: dispatch.memberDetail.storeDocuments,
    storeMemberFacePicture: dispatch.memberDetail.storeMemberFacePicture,
    deleteMemberFacePicture: dispatch.memberDetail.deleteMemberFacePicture,
  }))

  const close = () => toggleEditCurrentMember(false)
  const openEditModal = (type) => () => setEditModalOpen(type)
  const closeEditModal = () => setEditModalOpen(null)

  return (
    <BaseDialog
      open={open}
      handleClose={close}
      title="Edit Current Member"
      titleSx={{ border: `none` }}
      actionsSx={{ border: `none` }}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <MenuList
        sx={{
          marginLeft: 1,
          "& .MuiMenuItem-root": { mt: 1 },
          "& .MuiListItemIcon-root": { minWidth: `50px !important` },
        }}
      >
        <MenuItem onClick={openEditModal(MEDICAL)}>
          <ListItemIcon>
            <MedicalServices sx={{ color: `medical.main` }} />
          </ListItemIcon>
          <ListItemText>Edit Medical Information</ListItemText>
        </MenuItem>
        <MenuItem onClick={openEditModal(PERSONAL)}>
          <ListItemIcon>
            <AccountCircle
              sx={{ color: `personal.main`, filter: `brightness(0.85)` }}
            />
          </ListItemIcon>
          <ListItemText>Edit Personal Information</ListItemText>
        </MenuItem>
        <MenuItem onClick={openEditModal(IDENTIFYING)}>
          <ListItemIcon>
            <Visibility sx={{ color: `warning.main` }} />
          </ListItemIcon>
          <ListItemText>Edit Identifying Information</ListItemText>
        </MenuItem>
        <MenuItem onClick={openEditModal(DOCUMENTS)}>
          <ListItemIcon>
            <CreditCard sx={{ color: `documents.main` }} />
          </ListItemIcon>
          <ListItemText>Edit Additional Documents</ListItemText>
        </MenuItem>
        <MenuItem onClick={openEditModal(PHOTOS)}>
          <ListItemIcon>
            <Face sx={{ color: `primary.main` }} />
          </ListItemIcon>
          <ListItemText>Edit Profile Photos</ListItemText>
        </MenuItem>
      </MenuList>
      <PersonalDialog
        handleClose={closeEditModal}
        open={editModalOpen === PERSONAL}
        user={member}
        handleSubmit={updateMember}
        appType="provider"
      />
      <IdentifyingDialog
        handleClose={closeEditModal}
        open={editModalOpen === IDENTIFYING}
        user={member}
        addOtherPicture={api.addOtherPicture}
        handleSubmit={updateMember}
      />
      <MedicalInfoDialog
        handleClose={closeEditModal}
        open={editModalOpen === MEDICAL}
        user={member}
        handleSubmit={updateMember}
      />
      <DocumentsDialog
        handleClose={closeEditModal}
        open={editModalOpen === DOCUMENTS}
        user={member}
        handleSubmit={storeDocuments}
      />
      <FacePhotosDialog
        handleClose={closeEditModal}
        open={editModalOpen === PHOTOS}
        user={member}
        storeUserFacePicture={storeMemberFacePicture}
        deleteUserFacePicture={deleteMemberFacePicture}
        startingCameraType={CameraType.back}
      />
    </BaseDialog>
  )
}
