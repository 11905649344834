import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import Phone from "@mui/icons-material/Phone"
import { Box, IconButton, Typography } from "@mui/material"
import { orgEmergencyContacts } from "@erinfo/env/src/orgEmergencyContacts"

export const CallEmergencyContacts = ({ open, member, close }) => {
  const showOrgEmergencyContact =
    member?.type === "u/orgMember" && member?.pk === "university-of-miami"
  const orgEmergencyContact = orgEmergencyContacts[member?.pk ?? ``]

  const renderContact = (contact) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
      key={contact.phone}
    >
      <Box display="flex" flexDirection="column">
        <Typography>{contact.name}</Typography>
        <Typography sx={{ opacity: 0.54 }}>
          Phone: {parseNumber(contact.phone ?? ``)}
        </Typography>
        <Typography sx={{ opacity: 0.54 }}>
          Relationship: {contact.relationship}
        </Typography>
      </Box>
      <IconButton
        color="primary"
        sx={{ display: `flex`, flexDirection: `column` }}
        href={`tel:${contact.phone}`}
      >
        <Phone sx={{ width: 32, height: 32 }} />
        <Typography sx={{ fontSize: 12 }}>Call</Typography>
      </IconButton>
    </Box>
  )

  return (
    <BaseDialog
      open={open}
      handleClose={close}
      Icon={<Phone sx={{ mr: 2 }} />}
      title="Emergency Contacts"
      titleSx={{ color: `error.main` }}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <Box sx={{ pt: 1, pl: 3, pr: 3 }}>
        {showOrgEmergencyContact &&
          orgEmergencyContact &&
          renderContact(orgEmergencyContact)}
        {Array.isArray(member.emergencyContacts) &&
          member.emergencyContacts?.map((contact) => renderContact(contact))}
      </Box>
    </BaseDialog>
  )
}
