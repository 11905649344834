import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Box } from "@mui/material"

export const DocumentPictures = ({ open, document, close, getImage }) => {
  return (
    <BaseDialog
      open={!!open}
      title={document?.type}
      handleClose={close}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <Box sx={{ pt: 1, pl: 3, pr: 3 }}>
        <Box justifyContent="space-between" alignItems="center" mb={1}>
          <img
            src={getImage(document?.images?.[0])}
            style={{ width: `100%` }}
          />
          {document?.images?.[1] && (
            <img
              src={getImage(document?.images?.[1])}
              style={{ width: `100%`, marginTop: 10 }}
            />
          )}
        </Box>
      </Box>
    </BaseDialog>
  )
}
