import {
  AccountCircle,
  MedicalServices,
  Menu,
  Visibility,
} from "@mui/icons-material"
import AppBar from "@mui/material/AppBar"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"

export const MemberBottomMenu = ({ onSwitch, toggleMenu }) => {
  return (
    <AppBar
      position="sticky"
      sx={{
        top: `auto`,
        bottom: 0,
        bgcolor: `background.default`,
        zIndex: 500,
      }}
    >
      <Toolbar sx={{ justifyContent: `space-between` }}>
        <IconButton href="#medical" onClick={() => onSwitch(0)}>
          <MedicalServices sx={{ color: `medical.main` }} />
        </IconButton>
        <IconButton href="#personal" onClick={() => onSwitch(1)}>
          <AccountCircle sx={{ color: `personal.main` }} />
        </IconButton>
        <IconButton href="#identifying" onClick={() => onSwitch(2)}>
          <Visibility sx={{ color: `identify.main` }} />
        </IconButton>

        <IconButton aria-label="open drawer" onClick={() => toggleMenu(true)}>
          <Menu sx={{ color: `rgba(0,0,0,.54)` }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
