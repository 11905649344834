import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { MemberListItem } from "@erinfo/brand-ui/src/storybook/other/memberlist-item/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import AccessTime from "@mui/icons-material/AccessTime"
import { Box, CircularProgress, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const RecentMemberActivity = () => {
  const navigate = useNavigate()
  const open = useSelector(
    (state: RootState) => state.notifications.recentMemberActivityOpen,
  )
  const members = useSelector((state: RootState) => state.user.members) || []
  const matches = useSelector(
    (state: RootState) => state.matches.recentlyMatchedUsers,
  )
  const loading = useSelector((state: RootState) => state.matches.loading)
  const { toggleRecentMemberActivity, getMatchesByProvider, openMemberDetail } =
    useRematchDispatch((dispatch: Dispatch) => ({
      toggleRecentMemberActivity:
        dispatch.notifications.toggleRecentMemberActivity,
      getMatchesByProvider: dispatch.matches.getMatchesByProvider,
      openMemberDetail: dispatch.memberDetail.openMemberDetail,
    }))

  const activity = useMemo(
    () =>
      [...members, ...matches]
        .map((item) => {
          if (typeof item.created === `number`) {
            item.created = dayjs(item.created)
          } else if (typeof item.created === `string`) {
            item.created = dayjs(item.created, `MM/DD/YYYY`)
          }
          return item
        })
        .sort((a, b) => (a.created?.isBefore(b.created) ? 1 : -1))
        .reduce((agg, item) => {
          if (!agg.some((i) => i.id === item.id)) {
            agg.push(item)
          }
          return agg
        }, []),
    [members, matches],
  )

  const close = () => toggleRecentMemberActivity(false)

  const openMember = async (id, similarity) => {
    close()
    void openMemberDetail({ userId: id, similarity })
    navigate(`/member-detail/${id}`)
  }

  useEffect(() => {
    if (open) {
      void getMatchesByProvider({})
    }
  }, [open])

  const getAvatarImage = (member) => {
    if (!member.pictures?.length) return IPerson

    return member.pictures[0].src || IPerson
  }

  return (
    <BaseDialog
      open={open}
      handleClose={close}
      Icon={<AccessTime sx={{ opacity: 0.54, mr: 2 }} />}
      title="Recent Member Activity"
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <Box sx={{ pt: 1, pl: 3, pr: 3 }}>
        {activity?.map((member) => (
          <MemberListItem
            key={member.id}
            disposition={member.recentMatches?.find((i) => !!i.triageStatus)}
            avatar={getAvatarImage(member)}
            added={
              !member.similarity
                ? member.created?.format(`MM/DD/YYYY`)
                : undefined
            }
            matched={
              member.similarity
                ? dayjs(member.created).format(`MM/DD/YYYY`)
                : undefined
            }
            similarity={member.similarity}
            onClick={async () => openMember(member.id, member.similarity)}
          >
            {member.profile.lastName ? `${member.profile.lastName}, ` : ``}
            {member.profile.firstName}
            {!member.profile.lastName &&
              !member.profile.firstName &&
              `Unknown Name`}
          </MemberListItem>
        ))}
        {!activity?.length && !loading && (
          <Typography sx={{ mt: 1, opacity: 0.5 }}>
            There has been no member activity yet.
          </Typography>
        )}
        {loading && <CircularProgress />}
      </Box>
    </BaseDialog>
  )
}
