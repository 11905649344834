import Close from "@mui/icons-material/Close"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"
import { forwardRef, useEffect, useState } from "react"
import { useNetworkState } from "react-use"

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const OnlineStatus = () => {
  const [closeOfflineAlert, setCloseOfflineAlert] = useState(false)
  const [connectedAlert, setConnectedAlert] = useState(false)
  const { online, previous } = useNetworkState()

  const onFocus = () => setCloseOfflineAlert(false)

  useEffect(() => {
    if (online && previous === false) {
      setCloseOfflineAlert(false)
      setConnectedAlert(true)
    }
    if (!online) {
      setConnectedAlert(false)
    }
  }, [online, previous])

  useEffect(() => {
    window.addEventListener(`focus`, onFocus)

    return () => {
      window.removeEventListener(`focus`, onFocus)
    }
  }, [])

  return (
    <>
      <Dialog
        open={!online && !closeOfflineAlert}
        fullScreen
        sx={{ opacity: 0 }}
        disableEscapeKeyDown
        onClose={() => {}}
      />
      <Snackbar
        open={!online && !closeOfflineAlert}
        onClose={() => setCloseOfflineAlert(true)}
        anchorOrigin={{ vertical: `top`, horizontal: `center` }}
        ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
        sx={{
          "& .MuiPaper-root": { justifyContent: `space-between` },
          minWidth: 300,
        }}
      >
        <Alert
          onClose={() => setCloseOfflineAlert(true)}
          severity="warning"
          sx={{
            width: `100%`,
            fontSize: `1rem`,
            backgroundColor: `warning.light`,
            color: `rgba(0,0,0,0.89)`,
            lineHeight: `1.75`,
            "& .MuiAlert-action": {
              alignItems: `center`,
              padding: 0,
              ml: 6,
            },
          }}
          action={
            <>
              {/* <Button
                variant="contained"
                size="small"
                disableElevation
                sx={{ backgroundColor: `rgba(0,0,0,0.2)`}}
              >
                Try Again
              </Button> */}
              <IconButton onClick={() => setCloseOfflineAlert(true)}>
                <Close />
              </IconButton>
            </>
          }
          icon={false}
        >
          No Internet Connection
        </Alert>
      </Snackbar>
      <Snackbar
        open={connectedAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: `top`, horizontal: `center` }}
        onClose={() => setConnectedAlert(false)}
        sx={{ minWidth: 300 }}
      >
        <Alert
          onClose={() => setConnectedAlert(false)}
          severity="success"
          sx={{ width: `100%` }}
          icon={false}
        >
          Internet Connected!
        </Alert>
      </Snackbar>
    </>
  )
}
