import { Footer } from "@erinfo/brand-ui/src/storybook/other/footer"
import { HeaderWithLeftLogo } from "@erinfo/brand-ui/src/storybook/other/header/with-left-logo"
import { version } from "@erinfo/provider/package.json"
import { EditCurrentMember } from "@erinfo/provider/src/components/dialogs/EditCurrentMember"
import { EditProfile } from "@erinfo/provider/src/components/dialogs/EditProfile"
import { RecentMemberActivity } from "@erinfo/provider/src/components/dialogs/RecentMemberActivity"
import { MainMenu } from "@erinfo/provider/src/components/MainMenu"
import { MobileMainMenu } from "@erinfo/provider/src/components/MobileMainMenu"
import Notifications from "@erinfo/provider/src/components/Notifications"
import { useMainMenuActions } from "@erinfo/provider/src/hooks/use-main-menu-actions"
import { CssBaseline, Hidden, Typography } from "@mui/material"
import Link from "@mui/material/Link"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { FC } from "react"
import { Outlet } from "react-router-dom"

const StyledLink = styled(Link)({
  fontSize: `0.75rem`,
  cursor: `pointer`,
  fontWeight: 500,
  marginRight: 5,
  marginLeft: 5,
})

const Root = styled(`div`)({
  margin: `0 auto`,
  overflowY: `auto`,
  display: `grid`,
  gridTemplateRows: `auto 1fr`,
  minWidth: `100vw`,
  position: `relative`,
  "@media (min-width: 480px)": {
    height: `100vh`,
    footer: {
      position: `absolute`,
      bottom: `0`,
      padding: `8px 0`,
    },
  },
  "@media (max-width: 480px)": {
    justifyContent: `flex-start`,
    overflowY: `auto`,
    height: `100%`,
  },
})

const AppContent = styled(`div`)((props) => ({
  display: `flex`,
  flexDirection: `column`,
  background: `white`,
  width: `100%`,
  alignItems: `center`,
  "@media (min-width: 480px)": {
    justifyContent: `center`,
    overflowY: `auto`,
    position: `absolute`,
    top: 79,
    minHeight: `calc(100vh - 80px)`,
  },
  flex: 1,
}))

const AppLayout: FC = (props) => {
  const { children, sx } = props
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up(`lg`))
  const { sendEmail, logout } = useMainMenuActions()

  return (
    <Root id="root" sx={sx}>
      <CssBaseline />
      <HeaderWithLeftLogo renderRightSlot={MainMenu} proLogo inactive />
      <AppContent sx={sx}>
        {children}
        <Outlet />
        <Hidden smDown>
          <Footer
            renderLeftContent={
              <Typography variant="body2" color="textSecondary" align="center">
                v {version}
              </Typography>
            }
            renderRightContent={
              <>
                <StyledLink href="https://erinfo.me/faqs/" target="_blank">
                  Using ERinfo
                </StyledLink>
                {` `}|
                <StyledLink onClick={sendEmail(`profeedback@erinfo.me`)}>
                  Send Feedback
                </StyledLink>
                |<StyledLink onClick={logout}>Sign Out</StyledLink>
              </>
            }
          />
        </Hidden>
      </AppContent>
      <RecentMemberActivity />
      <EditCurrentMember />
      <EditProfile />
      <Hidden smUp>
        <MobileMainMenu />
      </Hidden>
      <Notifications />
    </Root>
  )
}

export default AppLayout
