import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import AppBar from "@mui/material/AppBar"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { makeStyles } from "@mui/styles"

import { MenuIcon } from "./icons"

const useStyles = makeStyles({
  root: {
    width: `100%`,
    zIndex: 100,
    position: `sticky`,
  },
  paper: {
    "@supports (top: constant(safe-area-inset-top))": {
      padding: `constant(safe-area-inset-top) 0 constant(safe-area-inset-bottom)`,
    },
    "@supports (top: env(safe-area-inset-top))": {
      padding: `env(safe-area-inset-top) 0 env(safe-area-inset-bottom)`,
    },
  },
  headerTransparent: {
    backgroundColor: `rgba(0, 0, 0, 0.3) !important`,
  },
  headerOpaque: {
    backgroundColor: `#fff`,
  },
  toolbar: {
    paddingLeft: 34,
    paddingRight: 0,
  },
  icon: {
    margin: `0 10px 0 15px`,
  },
  menuText: {
    textAlign: `end`,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 20,
  },
})

const Header = ({
  title,
  flowUnder = false,
  renderToolbar = null,
  color,
  background,
}: {
  title?: string
  flowUnder?: boolean
  renderToolbar?: () => void
  color?: string
  background?: string
}) => {
  const { toggleMenu } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleMenu: dispatch.notifications.toggleMenu,
  }))
  const classes = useStyles()

  return (
    <AppBar
      position="fixed"
      sx={{
        top: `auto`,
        bottom: 0,
        bgcolor: `background.default`,
        zIndex: 500,
      }}
      className={flowUnder ? classes.headerTransparent : classes.headerOpaque}
      style={{ backgroundColor: background }}
      elevation={1}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.grow}
          style={{ color: flowUnder ? `white` : `#000`, fontSize: `1rem` }}
        >
          {title}
        </Typography>
        {typeof renderToolbar === `function` && renderToolbar()}
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={() => toggleMenu(true)}
        >
          <MenuIcon color={color} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default Header
