import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"

const useStyles = makeStyles({
  attributionGrouping: {
    textAlign: `center`,
    fontFamily: `Roboto`,
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: `10px`,
    lineHeight: `12px`,
    color: `rgba(0, 0, 0, 0.25)`,
  },
  textVersion: { marginBottom: `10px` },
  textLink: {
    color: `inherit`,
    textDecoration: `none`,
  },
})

interface IProps {
  version: string
  className: string
}

export const Attribution = (props: IProps) => {
  const classes = useStyles()

  return (
    <div className={`${classes.attributionGrouping} ${props.className}`}>
      <p className={classes.textVersion}>ERinfo {props.version}</p>
      <p>&copy; 2018-{dayjs().year()} ERinfo Inc</p>
      <p>
        Patent:{` `}
        <a href="https://erinfo.me/patents" className={classes.textLink}>
          https://erinfo.me/patents
        </a>
      </p>
    </div>
  )
}
