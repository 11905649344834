import { init, RematchDispatch, RematchRootState } from "@rematch/core"
import createPersistPlugin from "@rematch/persist"
import selectPlugin from "@rematch/select"
import storage from "redux-persist/lib/storage"

import { models, RootModel } from "./models"

const persistPlugin = createPersistPlugin({
  key: `root`,
  storage,
  version: 2,
  whitelist: [`persisted`, `user`],
})

const store = init({
  models,
  plugins: [selectPlugin(), persistPlugin],
})

export default store
export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
