export default () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30.5" cy="30" r="30" fill="#E31B0C" />
    <path
      d="M41.834 15.8337H19.1673C17.609 15.8337 16.3482 17.1087 16.3482 18.667L16.334 44.167L22.0007 38.5003H41.834C43.3923 38.5003 44.6673 37.2253 44.6673 35.667V18.667C44.6673 17.1087 43.3923 15.8337 41.834 15.8337ZM39.0007 32.8337H22.0007V30.0003H39.0007V32.8337ZM39.0007 28.5837H22.0007V25.7503H39.0007V28.5837ZM39.0007 24.3337H22.0007V21.5003H39.0007V24.3337Z"
      fill="white"
    />
  </svg>
)
