import {
  addMatchForProvider,
  getMatchesByProvider,
  updateMatch,
} from "@erinfo/provider/src/data"
import { getTimeframe } from "@erinfo/provider/src/lib/timeUtils"
import { getImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { createModel } from "@rematch/core"
import dayjs from "dayjs"

import type { RootModel } from "./index"

export const MATCH_DAYS = 7

interface IMatchState {
  loading: boolean
  recentlyMatchedUsers: DataSchema.user.post[]
}

const initialState: IMatchState = {
  loading: false,
  recentlyMatchedUsers: [],
}

export const matches = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setLoading: (state: IMatchState, payload: boolean): IMatchState => ({
      ...state,
      loading: payload,
    }),
    setMatches: (
      state: IMatchState,
      payload: DataSchema.user.post[],
    ): IMatchState => ({
      ...state,
      recentlyMatchedUsers: payload,
      loading: false,
    }),
    addToMatches: (state: IMatchState, payload: object): IMatchState => ({
      ...state,
      recentlyMatchedUsers: [
        ...state.recentlyMatchedUsers.filter(
          ({ id, similarity }) =>
            id !== payload.id ||
            (id === payload.id && similarity >= payload.similarity),
        ),
        ...(!state.recentlyMatchedUsers.some(
          ({ id, similarity }) =>
            id === payload.id && similarity > payload.similarity,
        )
          ? [payload]
          : []),
      ],
    }),
  },
  effects: (dispatch) => ({
    getMatchesByProvider: async (
      { from, to }: { from?: number; to?: number },
      state,
    ) => {
      dispatch.matches.setLoading(true)
      const { id } = state.user
      const defaultTimefame = getTimeframe(MATCH_DAYS)
      from = from ?? defaultTimefame.from
      to = to ?? defaultTimefame.to
      try {
        const { matches, users } = await getMatchesByProvider(id, from, to)
        if (!users.length) return

        const dedupedMatches = matches.reduce((agg, curr) => {
          const dupMatchIdx = agg.findIndex((i) => i.userID === curr.userID)
          if (dupMatchIdx > -1) {
            if (agg[dupMatchIdx].created < curr.created) {
              agg[dupMatchIdx] = curr
            }
          } else {
            agg.push(curr)
          }
          return agg
        }, [])

        const matchedProfiles = dedupedMatches
          .reduce((agg, curr) => {
            const matchedUser = users.find((u) => u.id === curr.userID)
            if (matchedUser.pictures && matchedUser.pictures.length) {
              const { created } = matchedUser.pictures[0]
              const uri = getImageUrl(matchedUser.id, created)
              matchedUser.avatar = { uri }
            } else {
              matchedUser.avatar = null
            }
            if (curr.similarity) {
              matchedUser.similarity = curr.similarity.toFixed(2)
            }
            matchedUser.created = dayjs(curr.created)
            matchedUser.firstName = matchedUser.profile.firstName || ``
            matchedUser.lastName = matchedUser.profile.lastName || ``
            agg.push(matchedUser)
            return agg
          }, [])
          .sort((a, b) => Date.parse(b.created) - Date.parse(a.created))

        dispatch.matches.setMatches(matchedProfiles)
      } catch (err) {
        dispatch.matches.setLoading(false)
        console.log(err)
      }
    },

    addToRecentMatches: async ({
      providerId,
      user,
      similarity,
      base64Image,
    }) => {
      await addMatchForProvider(providerId, user.id, similarity)
      const newMatch = {
        ...user,
        similarity,
        avatar: { uri: base64Image },
      }
      dispatch.matches.addToMatches(newMatch)
    },

    updateRecentMatch: async ({ id, data }) => {
      await updateMatch(id, data)
    },
  }),
})
