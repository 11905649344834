import { createModel } from "@rematch/core"

import type { RootModel } from "./index"

export interface DialogNotificationState {
  title?: string
  msg?: string
  onYes?: () => void
  onNo?: () => void
  altYesLabel?: string
  altNoLabel?: string
  disableClose?: boolean
}

export interface SnackNotificationState {
  msg?: string
}

export interface NotificationState {
  dialog: DialogNotificationState
  snackbar: SnackNotificationState
  menuOpen: boolean
  recentMemberActivityOpen: boolean
  editCurrentMemberOpen: boolean
  profileOpen: boolean
}

const initialStateDialog = {
  title: ``,
  msg: ``,
  onYes: undefined,
  onNo: undefined,
  altYesLabel: `OK`,
  altNoLabel: ``,
  disableClose: false,
}

const initialState: NotificationState = {
  dialog: initialStateDialog,
  snackbar: {
    msg: ``,
  },
  menuOpen: false,
  recentMemberActivityOpen: false,
  editCurrentMemberOpen: false,
  profileOpen: false,
}

export const notifications = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSnackbarMessage: (
      state: NotificationState,
      payload: string,
    ): NotificationState => ({
      ...state,
      snackbar: { msg: payload },
    }),
    setDialogMessage: (
      state: NotificationState,
      payload: DialogNotificationState,
    ): NotificationState => ({
      ...state,
      dialog: {
        title: payload.title,
        msg: payload.msg,
        onYes: payload.onYes,
        onNo: payload.onNo,
        altYesLabel: payload.altYesLabel,
        altNoLabel: payload.altNoLabel,
        disableClose: payload.disableClose || false,
      },
    }),
    toggleMenu: (
      state: NotificationState,
      payload: boolean,
    ): NotificationState => ({
      ...state,
      menuOpen: payload,
    }),
    toggleRecentMemberActivity: (
      state: NotificationState,
      payload: boolean,
    ): NotificationState => ({
      ...state,
      recentMemberActivityOpen: payload,
    }),
    toggleEditCurrentMember: (
      state: NotificationState,
      payload: boolean,
    ): NotificationState => ({
      ...state,
      editCurrentMemberOpen: payload,
    }),
    toggleProfile: (
      state: NotificationState,
      payload: boolean,
    ): NotificationState => ({
      ...state,
      profileOpen: payload,
    }),
  },
})
