import * as schema from "@erinfo/data-schema"
import { getUserById } from "@erinfo/provider/src/data"
import * as api from "@erinfo/provider/src/data/index"
import { createModel } from "@rematch/core"

import type { RootModel } from "./index"

export type MemberDetailState = DataSchema.user.post & {
  fetching?: boolean
  id?: string
  similarity?: number
  matchId?: string
  matchImageCreated?: string
}

interface AddPicPayload {
  userId: string
  picture: {
    [schema.nameFaceId]: string
    created: string
  }
}
interface RemovePicPayload {
  userId: string
  faceId: string
}

const initialState: MemberDetailState = {
  fetching: false,
}

export const memberDetail = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFetching: (
      state: MemberDetailState,
      payload: boolean,
    ): MemberDetailState => ({
      ...state,
      fetching: payload,
    }),
    setOpenMemberDetail: (
      state: MemberDetailState,
      payload: object,
      merge?: boolean,
    ): MemberDetailState => ({
      ...payload,
      fetching: false,
      ...(merge ? state : {}),
    }),
    addPicture: (
      state: MemberDetailState,
      payload: AddPicPayload,
    ): MemberDetailState => {
      const { picture } = payload
      return {
        ...state,
        pictures: [...(state.pictures || []), picture],
      }
    },
    removePicture: (
      state: MemberDetailState,
      payload: RemovePicPayload,
    ): MemberDetailState => {
      const { faceId } = payload
      return {
        ...state,
        pictures: state.pictures?.filter((p) => p.faceId !== faceId),
      }
    },
  },

  effects: (dispatch) => ({
    async openMemberDetail({
      userId,
      matchId,
      identifiedImage,
      similarity,
      matchImageCreated,
    }: {
      userId: string
      matchId: string
      identifiedImage?: string
      similarity?: number
      matchImageCreated?: string
    }) {
      dispatch.memberDetail.setFetching(true)
      const user = await getUserById(userId)
      console.log(user)
      dispatch.memberDetail.setOpenMemberDetail({
        ...user,
        matchId,
        identifiedImage,
        matchImageCreated,
        similarity,
      })
    },

    updateMember: async (data, state) => {
      try {
        const userId = state.memberDetail.id
        if (userId) {
          const resp = await api.updateUser(userId, data)
          console.log(`TCL: onFormSubmit -> resp`, resp)

          const user = await api.getUserById(userId)
          dispatch.memberDetail.setOpenMemberDetail({
            ...user,
            identifiedImage: state.identifiedImage,
            similarity: state.similarity,
          })
          dispatch.user.updateMember(user)
          dispatch.notifications.setSnackbarMessage(`Changes saved`)
        }
      } catch (error) {
        if (error.message) {
          dispatch.notifications.setDialogMessage({
            msg: error.message,
          })
        }
        throw error
      }
    },

    async storeDocuments(data, state) {
      const userId = state.memberDetail.id
      data.documents = await Promise.all(
        data.documents.map(async (document) => {
          document.images = await Promise.all(
            document.images.map((image) =>
              image.startsWith(`data:image/png`)
                ? api.addOtherPicture(userId, image)
                : image.split(`?`)[0],
            ),
          )
          return document
        }),
      )
      await api.updateUser(userId, data)
      void dispatch.memberDetail.openMemberDetail({
        userId,
        identifiedImage: state.identifiedImage,
        similarity: state.similarity,
      })
      dispatch.notifications.setSnackbarMessage(`Changes saved`)
    },

    storeMemberFacePicture: async ({ userId, picture }, state) => {
      const result = await api.createUserFace({
        userId,
        created: picture.created,
        dataUrl: picture.value,
      })
      if (result?.[schema.nameFaceId]) {
        dispatch.memberDetail.addPicture({
          userId,
          picture: {
            [schema.nameFaceId]: result[schema.nameFaceId],
            created: result.created,
            src: picture.value,
          },
        })
        if (state.user?.members?.some((m) => m.id === userId)) {
          dispatch.user.updateMember(await api.getUserById(userId))
        }
      }
    },

    deleteMemberFacePicture: async ({ userId, faceId }, state) => {
      await api.deleteFacePicture(userId, faceId)
      dispatch.memberDetail.removePicture({ userId, faceId })
      if (state.user?.members?.some((m) => m.id === userId)) {
        dispatch.user.updateMember(await api.getUserById(userId))
      }
    },
  }),
})
