import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { isAndroid } from "@erinfo/react-utils/src/helpers/check-browser"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import Message from "@mui/icons-material/Message"
import { Box, IconButton, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { orgEmergencyContacts } from "@erinfo/env/src/orgEmergencyContacts"

export const MsgEmergencyContacts = ({ open, member, close, phone }) => {
  const showOrgEmergencyContact =
    member?.type === "u/orgMember" && member?.pk === "university-of-miami"

  const orgEmergencyContact = orgEmergencyContacts[member?.pk ?? ``]

  const renderEmergencyContact = (contact) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
      key={contact.phone}
    >
      <Box display="flex" flexDirection="column">
        <Typography>{contact?.name}</Typography>
        <Typography sx={{ opacity: 0.54 }}>
          Phone: {parseNumber(contact?.phone ?? ``)}
        </Typography>
        <Typography sx={{ opacity: 0.54 }}>
          Relationship: {contact?.relationship}
        </Typography>
      </Box>
      <Tooltip title="Requires SMS capability">
        <IconButton
          color="primary"
          sx={{ display: `flex`, flexDirection: `column` }}
          href={
            `sms:${contact?.phone}${isAndroid ? `?` : `&`}body=${
              contact.name
            }, ` +
            `I am a paramedic, please call me back about ${member.profile.firstName} ` +
            `${member.profile.lastName}.  ${
              phone && phone !== `NOT_FOUND` ? phone : ``
            }`
          }
        >
          <Message sx={{ width: 32, height: 32 }} />
          <Typography sx={{ fontSize: 12 }}>Message</Typography>
        </IconButton>
      </Tooltip>
    </Box>
  )

  return (
    <BaseDialog
      open={open}
      handleClose={close}
      Icon={<Message sx={{ mr: 2 }} />}
      title="Emergency Contacts"
      titleSx={{ color: `error.main` }}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <Box sx={{ pt: 1, pl: 3, pr: 3 }}>
        {showOrgEmergencyContact &&
          orgEmergencyContact &&
          renderEmergencyContact(orgEmergencyContact)}

        {Array.isArray(member.emergencyContacts) &&
          member.emergencyContacts?.map((contact) =>
            renderEmergencyContact(contact),
          )}
      </Box>
    </BaseDialog>
  )
}
