import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { getPresignedImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { Visibility } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC } from "react"

import { ProfileValueDisplay, StyledDivider, StyledFormCard } from "./common"

export const IdentifyingPanel: FC<{
  user: MemberDetailState
  onAdd: () => void
  sx: SxProps
}> = ({ user, onAdd, sx }) => {
  const {
    height,
    weight,
    race,
    hairColor,
    eyeColor,
    accessories,
    militaryVeteran,
  } = user?.identifyingInformation || {}
  const { identifyingMarks } = user || {}

  return (
    <StyledFormCard
      headerText="Identifying"
      Icon={<Visibility sx={{ color: `identify.main` }} />}
      headerSx={{ "@media (max-width: 480px)": { display: `none` } }}
      contentSx={{
        "@media (max-width: 480px)": {
          borderTop: `5px solid`,
          borderTopColor: `identify.main`,
        },
      }}
      sx={sx}
    >
      <ProfileValueDisplay label="Height" value={height} />
      <ProfileValueDisplay label="Weight" value={weight} />
      <ProfileValueDisplay label="Race" value={race} />
      <ProfileValueDisplay label="Hair Color" value={hairColor} />
      <ProfileValueDisplay label="Eye Color" value={eyeColor} />
      <ProfileValueDisplay
        label="Military Veteran"
        value={militaryVeteran ? `Yes` : `No`}
      />
      <Typography sx={{ opacity: !identifyingMarks?.length && 0.25 }}>
        Identifying Marks
      </Typography>
      {!identifyingMarks?.length && (
        <Typography sx={{ opacity: 0.25, ml: 2 }}>--</Typography>
      )}
      <Box pl={2}>
        {identifyingMarks?.map((mark) => (
          <Box
            sx={{ "& img": { minHeight: 65, maxHeight: 100 } }}
            key={`${mark.type}-${mark.location}`}
          >
            <Typography sx={{ opacity: 0.5, mb: 1 }}>
              {mark.type}, {mark.location}
              <br />
              {mark.desc}
            </Typography>
            {!!mark.img && (
              <img src={getPresignedImageUrl(mark.img, user.otherPictures)} />
            )}
          </Box>
        ))}
      </Box>

      <StyledDivider />

      <ProfileValueDisplay
        label="Notes"
        value={user?.notes}
        sx={{ whiteSpace: `normal` }}
      />
    </StyledFormCard>
  )
}
