import { EmergencyContactsList } from "@erinfo/provider/src/components/dashboard/EmergencyContactsList"
import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import { AccountCircle } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import dayjs from "dayjs"
import { FC } from "react"
import { Dispositions } from "@erinfo/provider/src/components/dashboard/DispositionRecords"

import { ProfileValueDisplay, StyledDivider, StyledFormCard } from "./common"

const getUpdatedColor = (updated) => {
  const daysSinceUpdated = dayjs().diff(dayjs(updated), `day`)
  if (daysSinceUpdated <= 182) return `success.main`
  if (daysSinceUpdated <= 365) return `warning.main`
  return `error.main`
}

export const PersonalPanel: FC<{
  user: MemberDetailState
  sx?: SxProps
  context?: "provider" | "consumer"
}> = ({ user, sx, context }) => {
  const {
    firstName,
    lastName,
    preferredName,
    birthDate,
    gender,
    lang,
    phoneNumbers,
    address = {},
  } = user?.profile || {}

  return (
    <StyledFormCard
      headerText="Personal"
      sx={sx}
      headerSx={{ "@media (max-width: 480px)": { display: `none` } }}
      contentSx={{
        "@media (max-width: 480px)": {
          borderTop: `5px solid`,
          borderTopColor: `personal.main`,
        },
      }}
      Icon={
        <AccountCircle
          sx={{ color: `personal.main`, filter: `brightness(0.85)` }}
        />
      }
    >
      <Typography
        variant="h6"
        sx={{ opacity: !firstName && !lastName ? 0.5 : 1 }}
      >
        {firstName} {lastName} {!firstName && !lastName ? `Unknown Name` : ``}
        {user?.type === `u/archived` ? `(archived profile)` : ``}
      </Typography>

      <ProfileValueDisplay
        label="DOB:"
        lightLabel
        stacked={false}
        value={
          !!birthDate && (
            <>
              {dayjs(birthDate, `YYYY-MM-DD`).format(`MM/DD/YYYY`)}
              {` `}({dayjs().diff(dayjs(birthDate, `YYYY-MM-DD`), `year`)})
            </>
          )
        }
      />
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            backgroundColor: getUpdatedColor(user?.updated),
            borderRadius: `50%`,
            width: 10,
            height: 10,
            mr: 1,
          }}
        />
        <ProfileValueDisplay
          label="Last Updated:"
          value={dayjs(user?.updated).format(`MM/DD/YYYY`)}
          stacked={false}
          lightLabel
        />
      </Box>
      {context === "provider" && user.recentMatches?.length && (
        <>
          <StyledDivider />
          <Dispositions user={user} />
        </>
      )}

      <StyledDivider />

      <ProfileValueDisplay
        label="Additional/Preferred Name"
        value={preferredName}
      />
      <ProfileValueDisplay label="Gender" value={gender} />
      <ProfileValueDisplay label="Language" value={lang} />

      <StyledDivider />

      <ProfileValueDisplay
        label="MOBILE Phone"
        value={parseNumber(phoneNumbers?.mobile || ``)}
      />
      <ProfileValueDisplay label="Email" value={user?.email} />
      <ProfileValueDisplay
        label="Address"
        value={
          address.street || address.city || address.state || address.zipCode
            ? () => (
                <>
                  {address.street ? `${address.street}, ` : ``}
                  {address.city ? `${address.city}, ` : ``}
                  {address.state} {address.zipCode}
                </>
              )
            : null
        }
      />

      <StyledDivider />

      <EmergencyContactsList user={user} />

      <StyledDivider />

      <ProfileValueDisplay
        label="Notes"
        value={user?.notesContact}
        sx={{ whiteSpace: `normal` }}
      />
    </StyledFormCard>
  )
}
