// import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { useMainMenuActions } from "@erinfo/provider/src/hooks/use-main-menu-actions"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export const MainMenu = () => {
  const {
    navigate,
    closeMenu,
    openRecentMember,
    openEditCurrentMember,
    openProfile,
    openAddMember,
    sendEmail,
    user,
    logout,
    disableMemberDetail,
  } = useMainMenuActions()

  return (
    <Box display="flex" sx={{ fontSize: `0.75rem` }}>
      <Link sx={{ mr: 1 }} href="#" onClick={openProfile}>
        Profile
      </Link>
      |
      <Link href="#" sx={{ mr: 1, ml: 1 }} onClick={openAddMember}>
        Add Member
      </Link>
      |
      <Link
        href="#"
        sx={{
          mr: 1,
          ml: 1,
          cursor: disableMemberDetail ? `default` : `pointer`,
          color: disableMemberDetail ? `#ccc` : undefined,
        }}
        onClick={!disableMemberDetail ? openEditCurrentMember : () => {}}
      >
        Edit Current Member
      </Link>
      |
      <Link href="#" sx={{ mr: 1, ml: 1 }} onClick={openRecentMember}>
        Recent Member Activity
      </Link>
      |
      <Link
        href="#"
        color="error"
        sx={{ mr: 1, ml: 1 }}
        onClick={() => {
          navigate(`/app/identify`)
          closeMenu()
        }}
      >
        Identify New
      </Link>
    </Box>
  )
}
