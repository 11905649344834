import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { Attribution } from "@erinfo/brand-ui/src/storybook/typography/attribution/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { yupEmailSchema, yupPasswordSchema } from "@erinfo/data-schema"
import { version } from "@erinfo/provider/package.json"
// import useAuth from "@erinfo/provider/src/hooks/use-auth"
// import { useRedirects } from "@erinfo/provider/src/hooks/use-redirects"
import { Dispatch } from "@erinfo/provider/src/store"
import { authSignIn } from "@erinfo/react-utils/src/helpers/amplify"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Hidden, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { FC, useState } from "react"
import * as Yup from "yup"

const ForgotPassword = styled(TextInfo)({ marginBottom: `25px` })
const NotAMember = styled(TextInfo)({ marginBottom: `15px`, marginTop: 10 })

const FormSchema = Yup.object().shape({
  email: yupEmailSchema.required(`Email is required`),
  password: yupPasswordSchema.required(`Password is required`),
})

const SignIn: FC = () => {
  const [loading, setLoading] = useState(false)
  // const { handleSignout } = useAuth()
  const { setDialogMessage, storeProviderByEmail } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      storeProviderByEmail: dispatch.user.storeProviderByEmail,
    }),
  )
  // useRedirects({ protectedRoute: false })

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const emailLowercase = values.email.toLowerCase()
      const cognitoUser = await authSignIn(emailLowercase, values.password)
      if (cognitoUser.response && cognitoUser.response.error) {
        throw cognitoUser
      }
      await storeProviderByEmail({ email: emailLowercase })
    } catch (error) {
      setLoading(false)
      const { response } = error

      if (response) {
        const { status, message } = response
        if (status === `auth`) {
          if (message && message.includes(`validation error`)) {
            setDialogMessage({ msg: `The entered email is not valid` })
          } else if (message === `User does not exist.`) {
            setDialogMessage({
              title: `User Doesn't Exist`,
              msg: `Please check the email and try again.`,
            })
          } else if (message === `Incorrect username or password.`) {
            setDialogMessage({
              title: `Incorrect Username or Password`,
              msg: `Please check the email and/or password and try again.`,
            })
          } else {
            setDialogMessage({ msg: message })
          }
        } else if (status === 404) {
          setDialogMessage({ msg: `User doesn't exist` })
        }
      }
    }
  }

  return (
    <>
      <BorderBox
        maxWidth={350}
        sx={{ "@media(max-width: 480px)": { flex: 1 } }}
      >
        <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
          Sign In
        </Typography>
        <Formik
          initialValues={{
            email: ``,
            password: ``,
          }}
          validationSchema={FormSchema}
          validateOnBlur
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={TextInput}
                name="email"
                label="Email"
                type="email"
              />
              <Field
                component={TextInput}
                name="password"
                label="Password"
                type="password"
                onKeyPress={(e) => e.key === `Enter` && submitForm()}
                sx={{ marginTop: 2 }}
              />
              <StyledButton
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                loadingPosition="end"
                loading={loading}
                sx={{ marginTop: 3 }}
              >
                Sign in
              </StyledButton>
            </Form>
          )}
        </Formik>
        <Box
          sx={{
            marginTop: 5,
            display: `flex`,
            flexFlow: `column`,
            alignItems: `center`,
          }}
        >
          <ForgotPassword>
            <TextAnchor to={`/forgot-password/send-email`}>
              Forgot Password?
            </TextAnchor>
            {` `}
          </ForgotPassword>
          <NotAMember>
            Sign in with{` `}
            <TextAnchor to={`/sign-up/step-1`}>FirstNet</TextAnchor>
          </NotAMember>
        </Box>
      </BorderBox>
      <Hidden smUp>
        <StyledAttribution>
          <Attribution
            className="sign-in__attribution"
            version={`v${version}`}
          />
        </StyledAttribution>
      </Hidden>
    </>
  )
}

const StyledAttribution = styled(`div`)({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
})

export default SignIn
