import { CameraCaptureDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/CameraDialog"
import { Logo } from "@erinfo/brand-ui/src/storybook/other/logo/_"
import { OnlineStatus } from "@erinfo/brand-ui/src/storybook/other/online-status"
import Header from "@erinfo/provider/src/components/header"
import { getUserById, matchFacePhoto } from "@erinfo/provider/src/data"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { resizeImage } from "@erinfo/react-utils/src/helpers/resize-image"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useCustomPageAction } from "@erinfo/react-utils/src/hooks/use-custom-page-action"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Hidden from "@mui/material/Hidden"
import { styled } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const StyledLogo = styled(Logo)({
  margin: `0 auto 38px`,
})

const MAX_IMAGE_WIDTH = 1000
const MAX_IMAGE_HEIGHT = 1000

const WebcamComponent = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [cameraOpen, setCameraOpen] = useState(false)
  const [image, setImage] = useState<string>()
  const [noPermissions, setNoPermissions] = useState(false)
  const {
    setOpenMemberDetail,
    openMemberDetail,
    checkToken,
    setDialogMessage,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    openMemberDetail: dispatch.memberDetail.openMemberDetail,
    setOpenMemberDetail: dispatch.memberDetail.setOpenMemberDetail,
    checkToken: dispatch.user.checkToken,
    setDialogMessage: dispatch.notifications.setDialogMessage,
  }))
  const user = useSelector((state: RootState) => state.user)
  const inputRef = useRef()
  const { resetPageAction, recordStep, reportPageAction } =
    useCustomPageAction(`identify`)

  /**
   * @note We need Data URL to later show img in the `<img />` tag as we don't return back originally taken picture
   *
   * @param imgBase64Raw in browser, the img's base64 comes as Data URL, whereas in Hybrid, it comes stripped from Data URL's identifiers @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
   * @param format in Hybrid, we use format to recreate Data URL from the browser
   */
  const submitImage = async (imgBase64Raw: string) => {
    setIsLoading(true)
    resetPageAction()
    try {
      const imgBase64Resized = await resizeImage(
        imgBase64Raw,
        MAX_IMAGE_WIDTH,
        MAX_IMAGE_HEIGHT,
      )
      recordStep(`image resized`)

      setOpenMemberDetail({
        identifiedImage: imgBase64Resized,
        similarity: 0,
      })

      const {
        created,
        match: { id, uid, similarity },
      } = await matchFacePhoto(imgBase64Resized, user.email)
      recordStep(`face matched`)
      console.log(uid, similarity)
      const matchedUser = await getUserById(uid)
      recordStep(`user profile retrieved`)
      console.log(matchedUser)
      await openMemberDetail({
        userId: uid,
        identifiedImage: imgBase64Resized,
        matchImageCreated: created,
        similarity,
        matchId: id,
      })
      recordStep(`member detail opened`)
      navigate(`/disposition`)
    } catch (err) {
      recordStep(`face not matched`)

      setOpenMemberDetail(
        {
          matchImageCreated: err?.details?.created,
        },
        true,
      )

      if (err.status === 400) {
        setImage(null)
        return setDialogMessage({
          title: `No match found`,
          msg: `There does not seem to be a face in the picture.`,
          altYesLabel: `Try again`,
          altNoLabel: ``,
          onYes: async () => requestToTakePicture(),
          disableClose: false,
        })
      }
      navigate(`/disposition`)
    } finally {
      reportPageAction()
    }
    setIsLoading(false)
  }

  const requestToTakePicture = async () => {
    try {
      const validToken = checkToken()
      if (!validToken) return navigate(`/`)

      setCameraOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (checkToken()) {
      void requestToTakePicture()
    } else {
      navigate(`/`)
    }
    inputRef.current.addEventListener(`change`, (e) => {
      const target = e.target || e.srcElement
      const url = URL.createObjectURL(target.files[0])
      setImage(url)
      void submitImage(url)
    })
  }, [])

  return (
    <Wrapper>
      <OnlineStatus />
      <CameraCaptureDialog
        isOpen={cameraOpen}
        onClose={() => setCameraOpen(false)}
        onCapture={(base64Uri) => {
          setImage(base64Uri)
          setCameraOpen(false)
          void submitImage(base64Uri)
        }}
      />
      <input
        capture
        ref={inputRef}
        type="file"
        accept="image/png,image/jpg,image/jpeg"
        style={{ display: `none` }}
      />
      <>
        {image && (
          <img src={image} style={{ objectFit: `cover`, height: `100vh` }} />
        )}
        {isLoading ? (
          <div className="overlay">
            <Loading style={{ color: `#fff` }} />
          </div>
        ) : (
          <>
            <Hidden smUp>
              <StyledLogo version="pro_vertical" />
            </Hidden>
            <Box
              sx={{
                display: `flex`,
                flex: 1,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <Box
                sx={{
                  position: `relative`,
                  width: 200,
                  height: 253,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0.411 0.309 80.307 99.359"
                >
                  <path
                    d="M25,2 L2,2 L2,25"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  />
                  <path
                    d="M2,75 L2,98 L25,98"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  />
                  <path
                    d="M 56 98 L 79 98 L 79 75"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  />
                  <path
                    d="M 79 25 L 79 2 L 56 2"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  />
                </svg>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: `#fff`,
                    position: `absolute`,
                    top: 100,
                    left: 56,
                  }}
                  onClick={requestToTakePicture}
                >
                  Identify
                </Button>
              </Box>
            </Box>
          </>
        )}
      </>
      <Hidden smUp>
        <Header
          flowUnder
          color="white"
          {...(!window.ReactNativeWebView && {
            renderToolbar: () => (
              <>
                {noPermissions && (
                  <Button
                    style={{ color: `#fff` }}
                    onClick={async () => {
                      location.reload()
                    }}
                  >
                    Enable
                  </Button>
                )}
              </>
            ),
          })}
        />
      </Hidden>
    </Wrapper>
  )
}

const Wrapper = styled(`div`)({
  position: `relative`,
  width: `100vw`,
  height: `calc(100vh - 80px)`,
  "@media (max-width: 480px)": {
    height: `calc(100vh - 100px)`,
  },
  overflow: `hidden`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  paddingTop: 20,
  "& video": { objectFit: `cover !important` },
  "@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)":
    {
      "#container-circles": { bottom: `30% !important` },
    },
  "& .overlay": { position: `absolute` },
})

const Loading = styled(CircularProgress)({ alignSelf: `center`, zIndex: 100 })

export default WebcamComponent
