import { Amplify } from "@aws-amplify/core"
import { envAmplify, newrelic } from "@erinfo/env"
import store from "@erinfo/provider/src/store"
import { GoogleAnalytics } from "@erinfo/react-utils/src/components/googleAnalytics"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { getPersistor } from "@rematch/persist"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { PersistGate } from "redux-persist/es/integration/react"

import App from "./app"
import theme from "./theme"

Amplify.configure(envAmplify)
const container = document.getElementById(`app`)
const root = createRoot(container)

console.log(`process.env.NODE_ENV:`, process.env.NODE_ENV)
if (process.env.NODE_ENV === `production`) {
  void newrelic.browser.helpers.loadNewRelicAgent()
}

root.render(
  <Provider store={store}>
    <PersistGate persistor={getPersistor()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <App />

              {process.env.NODE_ENV === `production` && (
                <GoogleAnalytics
                  gaTrackingId={
                    /**
                     * The measurement id is here @see https://analytics.google.com/analytics/web/?authuser=1#/a189025142p262783413/admin/streams/table/
                     */
                    process.env.AWS_STAGE === `production`
                      ? `G-8PXXDZJ5RV`
                      : `G-MFTYF5ND4Y`
                  }
                  gtmId={
                    process.env.AWS_STAGE === `production`
                      ? `GTM-MWT5PHP`
                      : `GTM-56JK2CD`
                  }
                />
              )}
            </StyledEngineProvider>
          </ThemeProvider>
        </Router>
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
)

void defineCustomElements(window)
