import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { DispositionForm } from "@erinfo/brand-ui/src/storybook/other/forms"
import * as attr from "@erinfo/data-schema/src/attribute"
import Header from "@erinfo/provider/src/components/header"
import Hidden from "@mui/material/Hidden"
import { Box } from "@mui/material"
import { Form, Formik } from "formik"
import { useRef, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { getCoords } from "@erinfo/react-utils/src/helpers/geolocation"

import { DispositionWrapper } from "@erinfo/provider/src/components/DispositionWrapper"

const FormSchema = Yup.object().shape({
  [attr.nameIncidentId]: Yup.string().required(`Incident ID is required`),
  [attr.nameTransportUnit]: Yup.string().required(`Transport unit is required`),
  [attr.namePatientDestination]: Yup.string().required(
    `Destination is required`,
  ),
  [attr.nameTriageStatus]: Yup.string().required(`Triage status is required`),
  [attr.nameInitialLocation]: Yup.string().when(attr.nameIncidentType, {
    is: `evacuation`,
    then: () => Yup.string().required(`Initial location is required`),
  }),
  [attr.nameEvacueeType]: Yup.string().when(attr.nameIncidentType, {
    is: `evacuation`,
    then: () => Yup.string().required(`Evacuee type is required`),
  }),
  [attr.nameEvacuationStatus]: Yup.string().when(attr.nameIncidentType, {
    is: `evacuation`,
    then: () => Yup.string().required(`Evacuation status is required`),
  }),
})

export interface FormValues {
  action: string
  [attr.nameIncidentId]: string
  [attr.nameTransportUnit]: string
  [attr.namePatientDestination]: string
  [attr.nameTriageStatus]: string
}

const PatientDisposition = () => {
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const provider = useSelector((state: RootState) => state.user)
  const user = useSelector((state: RootState) => state.memberDetail)
  const valueRef = useRef<FormValues>()
  const coordsRef = useRef<{ long: number; lat: number }>()
  const {
    updateRecentMatch,
    createPatientWithDisposition,
    openMemberDetail,
    setDialogMessage,
    storeProviderById,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    createPatientMember: dispatch.user.createPatientMember,
    updateRecentMatch: dispatch.matches.updateRecentMatch,
    openMemberDetail: dispatch.memberDetail.openMemberDetail,
    createPatientWithDisposition: dispatch.user.createPatientWithDisposition,
    setDialogMessage: dispatch.notifications.setDialogMessage,
    storeProviderById: dispatch.user.storeProviderById,
  }))
  if (!user?.identifiedImage) {
    navigate(`/identify`)
  }

  useEffect(() => {
    ;(async () => {
      const coords = await getCoords()
      coordsRef.current = coords
    })()
  }, [])

  const onSubmit = async (values: FormValues) => {
    const { action, ...vals } = values
    setSaving(true)

    sessionStorage.setItem(attr.nameIncidentId, values[attr.nameIncidentId])
    sessionStorage.setItem(
      attr.nameTransportUnit,
      values[attr.nameTransportUnit],
    )

    const disposition = {
      ...vals,
      [attr.nameLat]: coordsRef.current?.lat ?? 0,
      [attr.nameLong]: coordsRef.current?.long ?? 0,
    }

    if (user?.id) {
      await updateRecentMatch({ id: user.matchId, data: disposition })
      storeProviderById({ id: provider.id })
      if (action === "next") return navigate(`/identify`)

      await openMemberDetail({
        userId: user.id,
        identifiedImage: user.identifiedImage,
        similarity: user.similarity,
        matchId: user.matchId!!,
      })
      return navigate(`/member-detail/${user.id}`)
    }

    try {
      const newId = await createPatientWithDisposition({
        created: user.matchImageCreated,
        disposition,
      })
      storeProviderById({ id: provider.id })
      if (action === "next") return navigate(`/identify`)

      return navigate(`/member-detail/${newId}`)
    } catch (err) {
      setDialogMessage({ msg: "There was a problem adding this member" })
    } finally {
      setSaving(false)
    }
  }

  const onBack = () => {
    navigate(`/identify`)
  }

  return (
    <Formik
      initialValues={{
        action: "",
        [attr.nameIncidentType]: `massCasualty`,
        [attr.nameIncidentId]:
          sessionStorage.getItem(attr.nameIncidentId) || ``,
        [attr.nameTransportUnit]:
          sessionStorage.getItem(attr.nameTransportUnit) || ``,
        [attr.nameInitialLocation]: ``,
        [attr.nameEvacueeType]: ``,
        [attr.nameEvacuationStatus]: ``,
        [attr.namePatientDestination]: ``,
        [attr.nameTriageStatus]: ``,
      }}
      validationSchema={FormSchema}
      validateOnBlur
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, handleSubmit }) => {
        valueRef.current = values

        return (
          <DispositionWrapper
            currStep={1}
            formId="step-1"
            onBack={onBack}
            user={user}
            saving={saving}
            onSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          >
            <Form id="disposition">
              <Box
                sx={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  margin: 2,
                  justifyContent: `space-between`,
                  "@media(min-width: 480px)": {
                    width: 620,
                  },
                }}
              >
                <DispositionForm
                  setFieldValue={setFieldValue}
                  values={values}
                  favoriteDestinations={provider?.favoriteDestinations}
                />
              </Box>
            </Form>

            <Hidden smUp>
              <Header flowUnder color="white" />
            </Hidden>
          </DispositionWrapper>
        )
      }}
    </Formik>
  )
}

export default PatientDisposition
