import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameZip = `zip`

const regex: DataSchema.RegExDef[] = [
  ...helpers.hasLengths(0, 10),
  {
    exp: /^(?!\d{5}$|\d{5}-\d{4}$).*/g,
    msg: `Either ##### or #####-####`,
  },
]

export const regexZip = {
  regex,
}

export const zip = helpers.setRegexes(regex)
export const zipOptional = zip.optional()
export const zipRegexSchema = regex
