import { useRef } from "react"

interface ActionStepData {
  name: string
  time: number
  stepTime?: number
  cumTime?: number
}

export const useCustomPageAction = (actionName: string) => {
  const timings = useRef<ActionStepData[]>([])

  const resetPageAction = () => {
    timings.current = []
  }

  const recordStep = (name: string) => {
    const time = performance.now()
    let stepTime, cumTime
    if (timings.current?.length > 0) {
      stepTime = time - timings.current[timings.current.length - 1].time
      cumTime = time - timings.current[0].time
    }

    timings.current.push({
      name,
      time,
      stepTime,
      cumTime,
    })
  }

  const reportPageAction = () => {
    console.log(actionName, timings.current)
    if (typeof window.newrelic === `object`) {
      window.newrelic.addPageAction(actionName, {
        steps: JSON.stringify(timings.current),
      })
    }
  }

  return { resetPageAction, recordStep, reportPageAction }
}
