import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { getImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { Box } from "@mui/material"

export const MemberPictures = ({ open, member, close }) => {
  return (
    <BaseDialog
      open={!!open}
      handleClose={close}
      titleSx={{ display: `none` }}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
        </Box>
      )}
    >
      <Box sx={{ pt: 1, pl: 3, pr: 3 }}>
        {open === `memberPhotos` &&
          member.pictures?.map((picture) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <img src={picture.src} style={{ width: `100%` }} />
            </Box>
          ))}
        {open === `identified` && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <img src={member.identifiedImage} style={{ width: `100%` }} />
          </Box>
        )}
      </Box>
    </BaseDialog>
  )
}
