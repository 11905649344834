import React from "react"

export const MedicalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M9 -4.53768e-05C7.21997 -4.53768e-05 5.47991 0.527795 3.99987 1.51673C2.51983 2.50566 1.36628 3.91127 0.685088 5.5558C0.00389957 7.20034 -0.17433 9.00994 0.172937 10.7558C0.520203 12.5016 1.37737 14.1052 2.63604 15.3639C3.89472 16.6226 5.49836 17.4798 7.24419 17.827C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4801 16.4832 14.0001C17.4722 12.52 18 10.78 18 8.99995C18.0049 7.81669 17.7754 6.64416 17.3249 5.55002C16.8743 4.45588 16.2116 3.46178 15.3749 2.62508C14.5382 1.78838 13.5441 1.12563 12.4499 0.675069C11.3558 0.22451 10.1833 -0.00494017 9 -4.53768e-05ZM15 11H11V15H7V11H3V6.99995H7V2.99995H11V6.99995H15V11Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
)

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M11.99 2.00001C10.0126 2.00198 8.0802 2.59015 6.43703 3.69017C4.79385 4.79018 3.51369 6.35266 2.75834 8.18009C2.003 10.0075 1.80639 12.0179 2.19337 13.957C2.58035 15.8962 3.53355 17.6771 4.93247 19.0746C6.33139 20.4721 8.11324 21.4235 10.0528 21.8086C11.9923 22.1936 14.0025 21.995 15.8291 21.2378C17.6558 20.4807 19.217 19.1989 20.3154 17.5547C21.4138 15.9104 22 13.9774 22 12C22 10.6859 21.741 9.38477 21.2378 8.17086C20.7347 6.95695 19.9972 5.85412 19.0675 4.9254C18.1379 3.99668 17.0343 3.2603 15.8199 2.75834C14.6055 2.25638 13.3041 1.99869 11.99 2.00001ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00347 15.0615 4.60897C16.5233 5.21447 17.7727 6.23985 18.6518 7.55544C19.5308 8.87104 20 10.4178 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1572 14.1217 20 12 20Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
)

export const ListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M3 13H5V11H3V13ZM3 17H5V15H3V17ZM3 9H5V7H3V9ZM7 13H21V11H7V13ZM7 17H21V15H7V17ZM7 7V9H21V7H7Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
)

export const AddMemberIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M15 12C15.7911 12 16.5645 11.7654 17.2223 11.3259C17.8801 10.8864 18.3928 10.2616 18.6955 9.53074C18.9983 8.79983 19.0775 7.99556 18.9231 7.21964C18.7688 6.44372 18.3878 5.73098 17.8284 5.17157C17.269 4.61216 16.5563 4.2312 15.7804 4.07686C15.0044 3.92252 14.2002 4.00173 13.4693 4.30448C12.7384 4.60723 12.1136 5.11992 11.6741 5.77772C11.2346 6.43552 11 7.20888 11 8C11 9.06087 11.4214 10.0783 12.1716 10.8284C12.9217 11.5786 13.9391 12 15 12ZM6 10V7H4V10H1V12H4V15H6V12H9V10H6ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
)

export const ArchiveMemberIcon = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path id="svg_1" fillOpacity="0.87" fill="black" d="m15,12c0.7911,0 1.5645,-0.2346 2.2223,-0.6741c0.6578,-0.4395 1.1705,-1.0643 1.4732,-1.79516c0.3028,-0.73091 0.382,-1.53518 0.2276,-2.3111c-0.1543,-0.77592 -0.5353,-1.48866 -1.0947,-2.04807c-0.5594,-0.55941 -1.2721,-0.94037 -2.048,-1.09471c-0.776,-0.15434 -1.5802,-0.07513 -2.3111,0.22762c-0.7309,0.30275 -1.3557,0.81544 -1.7952,1.47324c-0.4395,0.6578 -0.6741,1.43116 -0.6741,2.22228c0,1.06087 0.4214,2.0783 1.1716,2.8284c0.7501,0.7502 1.7675,1.1716 2.8284,1.1716zm-9,-2l-0.64926,-0.00774l-0.81439,0l-0.53635,0.00774l-3,0l0,2l3,0l0.47989,0.00774l0.64502,0l0.87509,-0.00774l3,0l0,-2l-3,0zm9,4c-2.67,0 -8,1.34 -8,4l0,2l16,0l0,-2c0,-2.66 -5.33,-4 -8,-4z"/>
  </svg>
)

export const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M12 12C12.7911 12 13.5645 11.7654 14.2223 11.3259C14.8801 10.8864 15.3928 10.2616 15.6955 9.53074C15.9983 8.79983 16.0775 7.99556 15.9231 7.21964C15.7688 6.44372 15.3878 5.73098 14.8284 5.17157C14.269 4.61216 13.5563 4.2312 12.7804 4.07686C12.0044 3.92252 11.2002 4.00173 10.4693 4.30448C9.73836 4.60723 9.11365 5.11992 8.67412 5.77772C8.2346 6.43552 8 7.20888 8 8C8 9.06087 8.42143 10.0783 9.17157 10.8284C9.92172 11.5786 10.9391 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
      fill="black"
    />
  </svg>
)

export const FlipCameraIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M20.6 16.866C19.5 15.566 18.5 14.266 17.5 12.966H19.3C19.3426 11.6093 18.9986 10.2685 18.308 9.09987C17.6175 7.93128 16.6089 6.98312 15.4 6.36598C13.9926 5.63055 12.3853 5.36953 10.8176 5.62181C9.24985 5.87409 7.80557 6.62617 6.69998 7.76598C6.09998 7.16598 5.6 6.56599 5 5.96599C6.13529 4.74122 7.57906 3.84434 9.18008 3.36931C10.7811 2.89428 12.4805 2.85858 14.1 3.26598C16.3025 3.76817 18.271 4.99921 19.6867 6.75969C21.1023 8.52017 21.8822 10.707 21.9 12.966H23.8C22.6 14.266 21.6 15.566 20.6 16.866Z"
      fill={color || `white`}
    />
    <path
      d="M4.49997 12.966C4.4917 14.3538 4.87113 15.7163 5.59552 16.9001C6.31991 18.0838 7.36048 19.0418 8.59998 19.666C11.4 21.066 14.4 20.566 17 18.466L18.8 20.266C17.6088 21.4652 16.1163 22.3211 14.4796 22.7434C12.843 23.1658 11.1227 23.1391 9.49997 22.666C4.79997 21.366 2.39997 17.966 1.99997 13.066H0.199951C1.29995 11.666 2.29996 10.466 3.29996 9.06598C4.39996 10.366 5.39996 11.666 6.39996 12.966H4.49997Z"
      fill={color || `white`}
    />
  </svg>
)

export const MenuIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="12"
    viewBox="0 0 19 12"
  >
    <path
      d="M0.799805 12H18.7998V10H0.799805V12ZM0.799805 7H18.7998V5H0.799805V7ZM0.799805 0V2H18.7998V0H0.799805Z"
      fill={color || `#00f`}
    />
  </svg>
)
