import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { Box } from "@mui/material"

interface IProps {
  user: MemberDetailState
  onClick: (type: string) => void
}

export const AvatarList = ({ user, onClick }: IProps) => {
  const { pictures, identifiedImage } = user
  const imagePath = pictures[0]?.src

  return (
    <Box display="flex" sx={{ cursor: `pointer` }}>
      {identifiedImage && (
        <Avatar
          url={identifiedImage}
          containerSx={{ mr: 1 }}
          key={identifiedImage.slice(0, 10)}
          sx={{ borderRadius: 2, width: 64, height: 64 }}
          onClick={() => onClick(`identified`)}
        />
      )}
      {pictures?.length >= 1 && (
        <Avatar
          url={imagePath}
          key={imagePath}
          containerSx={{ mr: 1 }}
          sx={{ borderRadius: 2, width: 64, height: 64 }}
          onClick={() => onClick(`memberPhotos`)}
        />
      )}
    </Box>
  )
}
