interface ICoords {
  longitude: number
  latitude: number
}

export const getCoords = async () => {
  const pos: { coords: ICoords } = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })

  return {
    long: pos?.coords?.longitude,
    lat: pos?.coords?.latitude,
  }
}
