import "./_.scss"

import { ReactComponent as IMarketFirstnet } from "@erinfo/brand-ui/src/assets/svg/FIRSTNET_CERTIFIED.svg"
import React from "react"

interface IProps {
  className?: string
}

export const AppDistributionFirstnet = (props: IProps) => {
  const className = ((className = `market-logo`) => {
    if (props.className) className += ` ${props.className}`

    return className
  })()
  // TODO: change href
  return (
    <a href="https://firstnet.gov" target="_blank" className={className}>
      <IMarketFirstnet className="market-logo__icon" />
    </a>
  )
}
