import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import { MedicalServices } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import dayjs from "dayjs"
import { FC } from "react"

import {
  MedicalDataValue,
  ProfileValueDisplay,
  StyledDivider,
  StyledFormCard,
} from "./common"

export const MedicalPanel: FC<{
  user: MemberDetailState
  onAdd: () => void
  sx: SxProps
}> = ({ user, onAdd, sx }) => {
  const { allergies, medicalConditions, medications, medicalDevices } =
    user.medicalData || {}
  const { directives, profile } = user
  const {
    preferredTreatmentHospital,
    primaryCarePhysician,
    primaryCarePhysicianPhone,
  } = profile

  const noMedConditions = medicalConditions?.length === 0
  const noAllergies = allergies?.length === 0
  const noMedications = medications?.length === 0
  const noMedDevices = medicalDevices?.length === 0

  return (
    <StyledFormCard
      Icon={<MedicalServices sx={{ color: `medical.main` }} />}
      headerText="Medical"
      sx={sx}
      headerSx={{ "@media (max-width: 480px)": { display: `none` } }}
      contentSx={{
        overflowY: `auto`,
        "@media (max-width: 480px)": {
          borderTop: `5px solid`,
          borderTopColor: `error.main`,
        },
      }}
    >
      <Typography variant="h7" mb={2} sx={{ opacity: noMedConditions && 0.25 }}>
        Conditions
      </Typography>
      {medicalConditions?.length ? (
        medicalConditions?.map(({ title, onset }) => (
          <Box sx={{ marginBottom: 1, ml: 2 }} key={title}>
            <Typography>
              {title === `None` ? `None - No Medical Conditions` : title}
            </Typography>
            <MedicalDataValue
              label="Onset Date:"
              value={onset ? dayjs(onset, `YYYY-MM-DD`).format(`LL`) : null}
            />
          </Box>
        ))
      ) : (
        <Typography sx={{ opacity: 0.25, mb: 1, ml: 2 }}>--</Typography>
      )}

      <Typography
        variant="h7"
        mb={2}
        mt={2}
        sx={{ opacity: noAllergies && 0.25 }}
      >
        Allergies
      </Typography>
      {allergies?.length ? (
        allergies?.map(({ title, reaction, reactionDesc }) => (
          <Box sx={{ marginBottom: 1, ml: 2 }} key={title}>
            <Typography component="span">
              {title === `None` ? `None - No Allergies` : title}
            </Typography>
            <Typography
              color="error"
              component="span"
              sx={{ fontSize: `0.8rem`, ml: 2, fontWeight: 500 }}
            >
              {reaction && `ANAPHYLAXIS`}
              <br />
            </Typography>
            <MedicalDataValue label="Reaction:" value={reactionDesc} />
          </Box>
        ))
      ) : (
        <Typography sx={{ opacity: 0.25, mb: 1, ml: 2 }}>--</Typography>
      )}

      <Typography
        variant="h7"
        mb={2}
        mt={2}
        sx={{ opacity: noMedications && 0.25 }}
      >
        Medications
      </Typography>
      {medications?.length ? (
        medications?.map(({ title, dosage, frequency }) => (
          <Box sx={{ marginBottom: 1, ml: 2, mb: 1 }} key={title}>
            <Typography>
              {title === `None` ? `None - No Medications` : title}
            </Typography>
            <MedicalDataValue label="Dosage:" value={dosage} />
            <MedicalDataValue label="Frequency:" value={frequency} />
          </Box>
        ))
      ) : (
        <Typography sx={{ opacity: 0.25, mb: 1, ml: 2 }}>--</Typography>
      )}

      <Typography
        variant="h7"
        mb={2}
        mt={2}
        sx={{ opacity: noMedDevices && 0.25 }}
      >
        Medical Devices & Implants
      </Typography>
      {medicalDevices?.length ? (
        medicalDevices?.map(({ title, serialNumber, implantedDate }) => (
          <Box sx={{ marginBottom: 1, ml: 2 }} key={title}>
            <Typography>
              {title === `None` ? `None - No Medical Devices` : title}
            </Typography>
            <MedicalDataValue label="Serial Number:" value={serialNumber} />
            <MedicalDataValue
              label="Implant Date:"
              value={implantedDate ? dayjs(implantedDate).format(`LL`) : null}
            />
          </Box>
        ))
      ) : (
        <Typography sx={{ opacity: 0.25, mb: 1, ml: 2 }}>--</Typography>
      )}

      <Typography variant="h7" mb={2} mt={2}>
        Advanced Directives
      </Typography>
      {!!directives && (
        <Box sx={{ marginBottom: 1, ml: 2 }}>
          <MedicalDataValue
            label="I am an Organ Donor:"
            value={directives?.organDonor ? `YES` : `NO`}
          />
          <MedicalDataValue
            label="I have Advanced Directives:"
            value={directives?.hasDirectives ? `YES` : `NO`}
          />
          {directives?.hasDirectives && (
            <Box pl={2}>
              <MedicalDataValue
                label="Where found:"
                value={directives.location}
              />
              {(directives.name || directives.phone) && (
                <MedicalDataValue
                  label="Who has access:"
                  value={`${directives.name || ``} ${
                    parseNumber(directives.phone) || ``
                  }`}
                />
              )}
            </Box>
          )}
        </Box>
      )}

      <Typography
        variant="h7"
        mb={2}
        mt={2}
        sx={{ opacity: !preferredTreatmentHospital && 0.25 }}
      >
        Preferred Treatment Hospital
      </Typography>
      <ProfileValueDisplay
        label=""
        value={preferredTreatmentHospital}
        sx={{ ml: 2 }}
      />
      <Typography
        variant="h7"
        mb={2}
        mt={2}
        sx={{ opacity: !primaryCarePhysician && 0.25 }}
      >
        Primary Care Physician
      </Typography>
      <ProfileValueDisplay
        label=""
        sx={{ ml: 2 }}
        value={
          (primaryCarePhysician || primaryCarePhysicianPhone) &&
          `${primaryCarePhysician ?? ``} ${
            primaryCarePhysicianPhone
              ? `${primaryCarePhysician ? `\u2022` : ``} ${parseNumber(
                  primaryCarePhysicianPhone,
                )}`
              : ``
          }`
        }
      />

      <StyledDivider />

      <ProfileValueDisplay
        label="Notes"
        value={user?.notesMedical}
        sx={{ hiteSpace: `normal` }}
      />
    </StyledFormCard>
  )
}
