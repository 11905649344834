import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import { Box, Divider, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { ReactNode } from "react"

export const StyledFormCard = styled(FormCard)({
  width: 410,
  minHeight: 400,
  "@media(max-width: 480px)": {
    width: `100%`,
  },
})

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const NO_VAL = `--`

export const ProfileValueDisplay = ({
  label,
  value,
  stacked = true,
  lightLabel = false,
  sx,
}: {
  label: string
  value?: string | ReactNode
  stacked?: boolean
  lightLabel?: boolean
  sx?: SxProps
}) => {
  const val = value || NO_VAL

  const blank = val === NO_VAL

  return (
    <Box
      display="flex"
      flexDirection={stacked ? `column` : `row`}
      alignItems={!stacked ? `center` : `flex-start`}
      sx={sx}
    >
      <Typography sx={{ opacity: blank ? 0.25 : lightLabel ? 0.6 : 1 }}>
        {label}
      </Typography>
      <Typography
        sx={{
          opacity: value ? 0.6 : 0.25,
          mb: stacked ? 1 : 0,
          ml: !stacked ? 1 : blank ? 2 : 0,
        }}
      >
        {typeof val === `function` ? val() : val}
      </Typography>
    </Box>
  )
}

export const MedicalDataValue = ({ label, value }) =>
  !!value ? (
    <Box>
      <Typography sx={{ opacity: 0.26, mr: 1 }} component="span">
        {label}
      </Typography>
      <Typography sx={{ opacity: 0.54 }} component="span">
        {value}
      </Typography>
      <br />
    </Box>
  ) : (
    <></>
  )
