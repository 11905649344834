import * as React from "react"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { CircularProgress } from "@mui/material"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"

interface Option {
  label: string
  onClick: () => void
}

interface IProps {
  label: string
  options: Option[]
  color?: string
  disabled?: boolean
  loading?: boolean
}

export default function MenuButton({
  label,
  options,
  color,
  disabled,
  loading,
}: IProps) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <StyledButton
            uppercase={false}
            variant="contained"
            {...bindTrigger(popupState)}
            color={color ?? "primary"}
            disabled={disabled}
          >
            {label}
            {loading && <CircularProgress size={28} sx={{ ml: 2 }} />}
          </StyledButton>
          <Menu {...bindMenu(popupState)}>
            {options.map((option) => (
              <MenuItem
                key={option.label}
                disabled={disabled}
                onClick={() => {
                  option.onClick()
                  popupState.close()
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
