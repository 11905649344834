import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { FC, useState } from "react"
import { Box, Typography, IconButton } from "@mui/material"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import dayjs from "dayjs"
import { capitalize } from "lodash"
import * as attr from "@erinfo/data-schema/src/attribute"
import MapIcon from "@mui/icons-material/Place"
import Popover from "@mui/material/Popover"
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state"
import { getKeyFromCompositeId } from "@erinfo/data-schema/src/attribute"
import { getColor } from "@erinfo/brand-ui/src/storybook/other/forms/Disposition"
import { GOOGLE_MAPS_API_KEY } from "@erinfo/env/src/other"

const DispositionRow = ({ label, value, fullOpacityValue }: any) => (
  <Typography component="span" sx={{ opacity: 0.9 }}>
    {label}:{" "}
    <Typography component="span" sx={{ opacity: fullOpacityValue ? 1 : 0.7 }}>
      {value}
    </Typography>
  </Typography>
)

export const Dispositions: FC<{
  user: MemberDetailState
}> = ({ user }) => {
  const [currDispositionIdx, setCurrDispositionIdx] = useState(0)
  const currDisposition = user?.recentMatches[currDispositionIdx]
  const triageColor = getColor(currDisposition?.[attr.nameTriageStatus])

  return (
    currDisposition && (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Incident History</Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() =>
                currDispositionIdx > 0 &&
                setCurrDispositionIdx(currDispositionIdx - 1)
              }
            >
              <ChevronLeft />
            </IconButton>
            <Typography>{`${currDispositionIdx + 1} of ${
              user.recentMatches.length
            }`}</Typography>
            <IconButton
              onClick={() =>
                currDispositionIdx < user?.recentMatches?.length - 1 &&
                setCurrDispositionIdx(currDispositionIdx + 1)
              }
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h7">
            {currDisposition[attr.nameIncidentType] === "evacuation"
              ? "Evacuation Record"
              : "Mass Casualty Record"}
          </Typography>
          <DispositionRow
            label="Date/Time"
            value={dayjs(currDisposition.created).format("lll")}
          />
          <DispositionRow
            fullOpacityValue
            label="Photo Location"
            value={
              currDisposition[attr.nameLat] &&
              currDisposition[attr.nameLong] ? (
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <>
                      <IconButton {...bindTrigger(popupState)}>
                        <MapIcon color="primary" />
                      </IconButton>

                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <iframe
                          width="350"
                          height="250"
                          style={{ border: 0 }}
                          referrerPolicy="no-referrer-when-downgrade"
                          src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${
                            currDisposition[attr.nameLat]
                          },${currDisposition[attr.nameLong]}`}
                        ></iframe>
                      </Popover>
                    </>
                  )}
                </PopupState>
              ) : (
                ""
              )
            }
          />
          <DispositionRow
            label="Incident ID"
            value={currDisposition[attr.nameIncidentId]}
          />
          <DispositionRow
            label="Transport Unit"
            value={currDisposition[attr.nameTransportUnit]}
          />
          <DispositionRow
            label="Patient Destination"
            value={currDisposition[attr.namePatientDestination]}
          />
          <DispositionRow
            fullOpacityValue
            label="Triage Status"
            value={
              <Typography sx={{ color: triageColor }} component="span">
                ● {capitalize(currDisposition?.[attr.nameTriageStatus])}
              </Typography>
            }
          />
          {currDisposition[attr.nameIncidentType] === "evacuation" && (
            <>
              <DispositionRow
                label="Initial Location"
                value={currDisposition[attr.nameInitialLocation]}
              />
              <DispositionRow
                label="Evacuee Type"
                value={currDisposition[attr.nameEvacueeType]}
              />
              <DispositionRow
                label="Evacuation Status"
                value={currDisposition[attr.nameEvacuationStatus]}
              />
            </>
          )}

          <DispositionRow label="Organization" value={""} />
          <DispositionRow
            label="User ID"
            value={getKeyFromCompositeId(currDisposition.providerID)?.sk}
          />
        </Box>
      </Box>
    )
  )
}
