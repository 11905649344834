import FirstNetLogo from "@erinfo/brand-ui/src/assets/svg/FIRSTNET_CERTIFIED.svg"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import Person from "@mui/icons-material/Person"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { pick } from "lodash"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"

const MemberFormSchema = Yup.object().shape({
  providerType: Yup.string().required(`Required field`),
  stateLicensed: Yup.string().required(`Required field`),
  licenseNumber: Yup.string().required(`Required field`),
})

export const EditProfile = () => {
  const navigate = useNavigate()
  const open = useSelector(
    (state: RootState) => state.notifications.profileOpen,
  )
  const user = useSelector((state: RootState) => state.user)
  const { toggleProfile, updateUser, deleteUser, signOut, setDialogMessage } =
    useRematchDispatch((dispatch: Dispatch) => ({
      toggleProfile: dispatch.notifications.toggleProfile,
      updateUser: dispatch.user.updateUser,
      deleteUser: dispatch.user.deleteUser,
      signOut: dispatch.user.signOutFirstNet,
      setDialogMessage: dispatch.notifications.setDialogMessage,
    }))
  const isFirstNet = user?.type === `p/fn`

  const close = () => toggleProfile(false)

  const handleSubmit = async (data) => {
    await updateUser({
      data: pick(data, [`providerType`, `stateLicensed`, `licenseNumber`]),
    })
    close()
  }

  const onDelete = () => {
    setDialogMessage({
      title: `Delete Account`,
      msg:
        `Are you sure you want to permanently delete your user ` +
        `account and all associated data?`,
      onYes: async () => {
        await deleteUser()
        await signOut()
        close()
        navigate(`/`)
      },
      onNo: () => {},
      altYesLabel: `Yes`,
      altNoLabel: `No`,
    })
  }

  return (
    <BaseDialog
      open={open}
      handleClose={close}
      Icon={<Person sx={{ opacity: 0.54, mr: 2 }} />}
      title="Profile"
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={close}>Close</StyledButton>
          {/* <StyledButton type="submit" form="profile">
            Save
          </StyledButton> */}
        </Box>
      )}
    >
      <Formik
        initialValues={user}
        validateOnBlur
        onSubmit={handleSubmit}
        validationSchema={MemberFormSchema}
      >
        {({ setFieldValue, setFieldTouched }) => {
          return (
            <Form style={{ width: `100%` }} id="profile">
              <ProfileSection style={{ background: `#f4f4f4` }}>
                <ProfileSectionWrapper>
                  <ProfileSection style={{ padding: 0 }}>
                    {isFirstNet && (
                      <>
                        <img src={FirstNetLogo} style={{ width: 200 }} />
                        <div style={{ marginTop: 10 }} />
                      </>
                    )}
                    <Field
                      component={StyledTextInput}
                      name="firstName"
                      label="First Name"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="lastName"
                      label="Last Name"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="orgName"
                      label="Organization Name"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="orgID"
                      label="Organization ID"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="email"
                      label="Email"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="phone"
                      label="Phone Number"
                      disabled
                    />
                    <Field
                      component={StyledTextInput}
                      name="zip"
                      label="Postal Code"
                      disabled
                    />
                  </ProfileSection>
                  <br />
                </ProfileSectionWrapper>
              </ProfileSection>
            </Form>
          )
        }}
      </Formik>
      {process.env.AWS_STAGE !== `production` && (
        <Box display="flex" justifyContent="center" pb={3} pt={1}>
          <StyledButton
            onClick={onDelete}
            variant="outlined"
            color="error"
            sx={{ width: `300px`, mv: 2 }}
          >
            DELETE ACCOUNT
          </StyledButton>
        </Box>
      )}
    </BaseDialog>
  )
}

const ProfileSection = styled(`div`)({
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  width: `100%`,
  padding: `17px 0 20px`,
  margin: `0 auto`,
})

const ProfileSectionWrapper = styled(`div`)({
  width: `100%`,
  maxWidth: `300px`,
})

const StyledTextInput = styled(TextInput)({
  marginBottom: `18px !important`,
})
