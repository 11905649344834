import { Auth } from "@aws-amplify/auth"
import { getCompositeId, sourceTypeProvider } from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as env from "@erinfo/env"
import { envFirstNetLogoutUrl } from "@erinfo/env"
import * as api from "@erinfo/provider/src/data/index"
import { getTimeframe } from "@erinfo/provider/src/lib/timeUtils"
import { MATCH_DAYS } from "@erinfo/provider/src/store/models/matches"
import { tryCatchWrapper } from "@erinfo/react-utils/src/helpers/try-catch-wrapper"
import { createModel } from "@rematch/core"
import jwt_decode from "jwt-decode"

import type { RootModel } from "./index"

interface EnrolledPatient {
  date: number
  userID: string
  status?: string
}
interface UserState {
  fetching?: boolean
  id?: string
  enrolledPatients?: EnrolledPatient[]
  members?: DataSchema.user.post & { id: string }[]
  phone?: string
  impersonation?: { adminEmail: string }
  token: string
  favoriteDestinations?: string[]
}

const initialState: UserState = {}

export const user = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFetching: (state: UserState): UserState => ({
      ...state,
      fetching: true,
    }),
    setSignupInfo: (state: UserState, payload: object): UserState => ({
      ...state,
      ...payload,
    }),
    setUser: (state: UserState, payload: object): UserState => ({
      ...state,
      ...payload,
    }),
    setMembers: (
      state: UserState,
      payload: DataSchema.user.post[],
    ): UserState => ({
      ...state,
      members: payload,
    }),
    addMember: (
      state: UserState,
      payload: DataSchema.user.post,
    ): UserState => ({
      ...state,
      members: [...(state.members ?? []), payload],
    }),
    updateMember: (
      state: UserState,
      payload: DataSchema.user.post,
    ): UserState => ({
      ...state,
      members: state.members?.map((m) => (m.id === payload.id ? payload : m)),
    }),
    signOut: (state: UserState): UserState => ({}),
    setImpersonation: (state: UserState, payload: string): UserState => ({
      ...state,
      impersonation: {
        adminEmail: payload,
      },
    }),
  },

  effects: (dispatch) => ({
    checkToken: () => {
      const token = localStorage.getItem(`token`)
      if (token) {
        const decoded: { exp: number } = jwt_decode(token)
        if (Date.now() >= decoded.exp * 1000) {
          console.log(`token expired`)
          localStorage.removeItem(`token`)
          dispatch.user.signOut()
          return false
        }
      } else {
        dispatch.user.signOut()
        return false
      }
      return true
    },

    storeProviderById: tryCatchWrapper(async (payload) => {
      if (!payload?.id && !payload?.sub) return
      const id = payload?.sub
      if (payload.token) {
        localStorage.setItem(`token`, payload.token)
      } else {
        const token = localStorage.getItem(`token`)
        if (token) {
          payload.token = token
        }
      }
      console.log(`payload`, payload)
      const fnId = payload?.id && !payload.sub ? payload.id : `fn${id}:fn${id}`
      const user = await api.getProviderById(fnId)
      console.log(user)

      dispatch.user.setUser({ ...user, token: payload.token })
      void dispatch.user.expandMemberDetails(null)

      env.newrelic.browser.helpers.addUserDataToSession({
        id: user.id,
        email: user.email,
        phone: user.phone,
        lastName: user.lastName,
      })
      return user
    }),

    expandMemberDetails: tryCatchWrapper(async (_, state) => {
      const { enrolledPatients } = state.user
      if (!enrolledPatients?.length) return

      const { from } = getTimeframe(MATCH_DAYS)
      const recentMembers = enrolledPatients.filter((p) => p.date > from)

      const memberPromises: Promise<DataSchema.user.post>[] = []
      for (const member of recentMembers || []) {
        if (!member.status || !member.status.includes(`archived`)) {
          memberPromises.push(api.getUserById(member.userID))
        }
      }
      const members = await Promise.all(memberPromises)
      dispatch.user.setMembers(members)
    }),

    // For future Cognito pool for providers
    storeProviderByEmail: tryCatchWrapper(
      async ({ email, id }: { email?: string; id?: string }) => {
        dispatch.user.setFetching()
        let user: DataSchema.user.post

        if (email) {
          user = await api.getProviderByEmail(email)
        } else if (id) {
          user = await api.getProviderById(id)
        } else {
          throw new Error(`Need either email or ID to fetch user`)
        }

        if (!user.isProvider) {
          dispatch.notifications.setDialogMessage({
            msg: `Please use the Consumer version of ERinfo. This app is for providers`,
          })
        }

        dispatch.user.setUser(user)
        void dispatch.user.expandMemberDetails(null)
      },
    ),

    signOutFirstNet: tryCatchWrapper(() => {
      window.open(envFirstNetLogoutUrl, "_self")

      localStorage.removeItem(`token`)
      dispatch.user.signOut()
    }),

    createPatientMember: tryCatchWrapper(async ({ data, created }, state) => {
      const userId = state.user.id
      const newUserId = await api.createUser({
        ...data,
        addedBy: {
          userID: userId,
          source_type: sourceTypeProvider,
        },
      })
      await api.createUserFace({ userId: newUserId, created })
      const newMember = await api.getUserById(newUserId)
      dispatch.user.addMember(newMember)
      return newUserId
    }),

    createPatientWithDisposition: tryCatchWrapper(
      async ({ created, disposition }) => {
        const newUserId = await api.createNewUserWithDisposition(
          created,
          disposition,
        )
        const newMember = await api.getUserById(newUserId)
        dispatch.user.addMember(newMember)
        return newUserId
      },
    ),

    addUser: tryCatchWrapper(async (data: DataSchema.provider.post) => {
      const fnFieldMapping = {
        sub: attr.nameSub,
        given_name: attr.nameFirstName,
        family_name: attr.nameLastName,
        email: attr.nameEmail,
        org_name: attr.nameOrgName,
        orgID: attr.nameOrgId,
        phone_number: attr.namePhone,
        postal_code: attr.nameZip,
      }
      const payload = Object.entries(data).reduce((agg, [field, value]) => {
        const targetField = fnFieldMapping[field]
        if (targetField) {
          agg[targetField] = value
        }
        return agg
      }, {})

      if (/^(?!\d{5}$|\d{5}-\d{4}$).*/g.test(payload[attr.nameZip])) {
        delete payload[attr.nameZip]
      }

      try {
        const userId = await api.createUser(payload, true)
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await dispatch.user.storeProviderById({
          id: userId,
          token: data.token,
        })
      } catch (err) {
        console.log(`err`, err)
        dispatch.notifications.setSnackbarMessage(
          `Something went wrong.  Please try again.`,
        )
      }
    }),

    updateUser: tryCatchWrapper(async ({ data }, state) => {
      const userId = state.user.id
      if (userId) {
        await api.updateUser(userId, data, true)
        dispatch.user.setUser(data)
      }
    }),

    deleteUser: tryCatchWrapper(async () => api.deleteUser()),

    impersonate: tryCatchWrapper(async ({ email, password, userId }) => {
      dispatch.user.signOut()
      localStorage.removeItem(`token`)
      await Auth.signOut()

      const cognitoUser = await Auth.signIn(email, password, {
        idImpersonated: userId,
      })

      const provider = {
        token: cognitoUser?.signInUserSession?.idToken?.jwtToken,
        id: userId,
      }

      await dispatch.user.storeProviderById(provider)
      dispatch.user.setImpersonation(email)
      return cognitoUser
    }),
  }),
})
