import { MemberDetailState } from "@erinfo/provider/src/store/models/memberDetail"
import { getPresignedImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { CreditCard } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FC } from "react"

import { StyledFormCard } from "./common"

const DocImage = styled(`img`)({
  display: `block`,
  obectFit: `cover`,
  maxWidth: `66px`,
  maxHeight: `66px`,
  borderRadius: 5,
  border: `none`,
})

export const DocumentsPanel: FC<{
  user: MemberDetailState
  openDocument: (data: any) => void
}> = ({ user, openDocument }) => {
  return (
    <StyledFormCard
      id="documentsPanel"
      Icon={<CreditCard sx={{ color: `documents.main` }} />}
      headerText="Additional Documents"
      sx={{ minHeight: `unset` }}
    >
      {user?.documents?.length ? (
        user.documents.map((doc, idx) => (
          <Box mb={2} key={`${doc.type}-${idx}`}>
            <Typography sx={{ mb: 2 }}>{doc.type}</Typography>
            <Box display="flex" onClick={() => openDocument(doc)}>
              {doc.images.map((img) => (
                <DocImage
                  key={img}
                  src={getPresignedImageUrl(img, user.otherPictures)}
                  sx={{ marginRight: 3 }}
                />
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <Typography sx={{ opacity: 0.54 }}>
          Use this section to upload additional IDs that could help first
          responders.
        </Typography>
      )}
    </StyledFormCard>
  )
}
