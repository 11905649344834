import { envConsumerUrl } from "@erinfo/env"
import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

export const useMainMenuActions = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const {
    toggleMenu,
    toggleRecentMemberActivity,
    toggleEditCurrentMember,
    toggleProfile,
    signOutFirstNet,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleMenu: dispatch.notifications.toggleMenu,
    toggleRecentMemberActivity:
      dispatch.notifications.toggleRecentMemberActivity,
    toggleEditCurrentMember: dispatch.notifications.toggleEditCurrentMember,
    toggleProfile: dispatch.notifications.toggleProfile,
    signOutFirstNet: dispatch.user.signOutFirstNet,
  }))
  const open = useSelector((state: RootState) => state.notifications.menuOpen)
  const user = useSelector((state: RootState) => state.user)
  const memberDetail = useSelector((state: RootState) => state.memberDetail)
  const disableMemberDetail =
    !memberDetail ||
    Object.keys(memberDetail).length < 2 ||
    memberDetail.type === `u/archived`
  const isFirstNet = user?.type === `p/fn`

  const closeMenu = () => toggleMenu(false)
  const openHandler = (fn) => () => {
    fn(true)
    closeMenu()
  }
  const openRecentMember = openHandler(toggleRecentMemberActivity)
  const openEditCurrentMember = openHandler((id) => {
    if (!pathname.includes(`member-detail`)) {
      navigate(`/member-detail/${memberDetail.id}`)
    }
    toggleEditCurrentMember(id)
  })
  const openProfile = openHandler(toggleProfile)
  const openAddMember = openHandler(() => {
    confirm(``, {
      title: `Email and Mobile Phone Available?`,
      description: `Does the new member have an email address and mobile phone to receive an SMS message?`,
      yesAction: `Yes`,
      onNo: () => navigate(`/add-member/step-1`),
    })
      .then(() => {
        const newTab = window.open(
          `${envConsumerUrl}/sign-up/step-1?rc=PC202207&loasu=true`,
          `_blank`,
        )
        setTimeout(
          () =>
            newTab?.postMessage(`provider app registration`, envConsumerUrl),
          1000,
        )
        window.addEventListener(`message`, (e) => {
          if (e.origin !== envConsumerUrl) {
            return console.log(`Bad Origin in message ${e.origin}`)
          }
          newTab?.close()
        })
      })
      .catch(() => {})
  })

  const sendEmail = (to) => () => {
    window.open(`mailto:${to}`, `_top`)
  }

  const logout = async () => {
    closeMenu()
    if (
      await confirm(``, {
        title: `Sign Out?`,
        description: `${
          isFirstNet
            ? `Signing out will redirect to the ATT FirstNet page. `
            : ``
        }Are you sure you want to sign out?`,
        yesAction: `Continue Sign Out`,
        noAction: `Cancel`,
        yesColor: `error`,
        noColor: `primary`,
      })
    ) {
      await signOutFirstNet()
    }
  }

  return {
    navigate,
    closeMenu,
    openRecentMember,
    openEditCurrentMember,
    openProfile,
    openAddMember,
    sendEmail,
    logout,
    open,
    user,
    disableMemberDetail,
  }
}
