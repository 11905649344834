import { Dispatch, RootState } from "@erinfo/provider/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useRoutes } from "react-router-dom"

import routes from "./routes"

dayjs.extend(localizedFormat)

const App = () => {
  const user = useSelector((state: RootState) => state.user)
  const { setDialogMessage, createPatientMember, storeProviderById } =
    useRematchDispatch((dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      createPatientMember: dispatch.user.createPatientMember,
      storeProviderById: dispatch.user.storeProviderById,
    }))
  const content = useRoutes(
    routes({ user, setDialogMessage, createPatientMember }),
  )

  useEffect(() => {
    if (user.id) {
      void storeProviderById({ id: user.id })
    }
  }, [])

  return content
}

export default App
