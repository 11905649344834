import { request } from "./request"

export const getMatchesByProvider = async (id, from, to) => {
  const {
    data: { matches, users },
  } = await request({
    path: `/matches/by-provider/${id}/${from}/${to}`,
    signRequest: true,
  })
  return { matches, users }
}

export const addMatchForProvider = async (providerID, userID, similarity) => {
  await request({
    method: `POST`,
    path: `/matches`,
    body: { providerID, userID, similarity },
    signRequest: true,
  })
}

export const updateMatch = async (id, data) => {
  await request({
    method: `PATCH`,
    path: `/matches/${id}`,
    body: data,
    signRequest: true,
  })
}
